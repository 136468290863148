import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType
} from '@rappider/rappider-components/utils';
import { UIDataUpdateFunctionGeneratedBy } from '../../../models/ui-data-update-function';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const UI_DATA_UPDATE_FUNCTIONS_LIST_CONFIG: CrudTableViewConfig = {
  searchable: false,
  columnVisibilitySelectable: false,
  itemActionsLength: 5,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_LIST_COMPONENT.DATA_EVENT_NAME',
      fieldName: 'dataEventName',
      type: CrudViewColumnType.Text
    }
  ],

  itemActions: [
    {
      text: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.DATA_MAPPINGS',
      name: 'addDataMapping',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.CREATE_DATA_MAPPING_PAGE}/{{id}}`,
      icon: { name: 'far fa-plus-circle' },
      disabled: true
    },
    {
      text: 'Edit Code',
      name: 'editCode',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.EDIT_CODE}/{{id}}`,
      icon: { name: 'far fa-edit' },
      disabled: true
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ],

  handleVisibilityFn: (data, item, action) => {
    if (action.name === POP_CONFIRM_DELETE_ACTION.name) {
      return true;
    } else if (item.generatedBy) {
      if (item.generatedBy === UIDataUpdateFunctionGeneratedBy.Rappider && action.name === 'addDataMapping') {
        return true;
      } else if (item.generatedBy === UIDataUpdateFunctionGeneratedBy.User && action.name === 'editCode') {
        return true;
      }
    } else {
      if (action.name === 'addDataMapping') {
        return true;
      } else if (action.name === 'editCode') {
        return true;
      }
    }
  }
};
