import { UserRoles } from './../../../../shared/src/lib/guards/admin-guard/admin-dashboard-roles.enum';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthenticationActions from './authentication.actions';
import { map, catchError, mergeMap, concatMap, withLatestFrom, delay } from 'rxjs/operators';
import {
  VerificationTokenApi,
} from '@rappider/api-sdk';
import { Router } from '@angular/router';
import { TokenService } from '../services/token-service/token.service';
import { AUTH_REDIRECTION_DEFINITIONS, LoginType, PATH_DEFINITIONS, QueryParam, UsernameType, delayTimeForVerifyEmailSuccessfulPage } from '@rappider/shared/definitions';
import { NotificationService, BrowserStorageManagementService, RouterStateService } from '@rappider/services';
import { Action, Store } from '@ngrx/store';
import {
  LoginResponseDto,
  Person,
  PersonControllerService,
  PersonInvitationControllerService,
  ProjectRole,
  Tenant,
  TenantControllerService,
  User,
  UserControllerService,
  VerificationTokenControllerService
} from '@rappider/rappider-sdk';

/* get active project state actions */
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
/* get router state actions */
import * as RouterActions from 'libs/shared/src/lib/states/router/router.actions';
import * as ProjectActions from 'libs/project/src/lib/states/projects-state/project.actions';
import * as NotificationActions from 'libs/shared/src/lib/states/notification/notification.actions';
import { LoadComponentDefinitions } from 'libs/component-definition/src/lib/state/component-definition.actions';
import * as AppActions from 'apps/rappider/src/app/state/app.actions';
import * as PaymentActions from 'libs/payment/src/lib/state/payment.actions';
import { AccessTokenControllerService } from '@rappider/rappider-sdk';
import { TierType } from '../utils/tier-type.enum';

@Injectable()
export class AuthenticationEffects {

  count = 0;

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private routerStateService: RouterStateService,
    private personApi: PersonControllerService,
    private notificationService: NotificationService,
    private router: Router,
    private tokenService: TokenService,
    private browserStorageManagementService: BrowserStorageManagementService,
    private verificationTokenApi: VerificationTokenApi,
    private tenantApi: TenantControllerService,
    private userApi: UserControllerService,
    private personInvitationApi: PersonInvitationControllerService,
    private verificationApi: VerificationTokenControllerService,
    private accessTokenService: AccessTokenControllerService
  ) { }


  enableUserLoading$ = createEffect(() => this.actions$.pipe(
    ofType<
      AuthenticationActions.Register
      | AuthenticationActions.Login
      | AuthenticationActions.LoginByAuthenticationToken
    >(
      AuthenticationActions.ActionTypes.Register,
      AuthenticationActions.ActionTypes.Login,
      AuthenticationActions.ActionTypes.LoginByAuthenticationToken
    ),
    map(() => new AuthenticationActions.EnableUserLoading())
  ));


  disableUserLoading$ = createEffect(() => this.actions$.pipe(
    ofType<
      AuthenticationActions.LoggedIn
      | AuthenticationActions.LoginFailure
      | AuthenticationActions.RegisterFailure
    >(
      AuthenticationActions.ActionTypes.LoggedIn,
      AuthenticationActions.ActionTypes.LoginFailure,
      AuthenticationActions.ActionTypes.RegisterFailure
    ),
    map(() => new AuthenticationActions.DisableUserLoading())
  ));


  setAuthenticationToken$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SetAuthenticationToken>(AuthenticationActions.ActionTypes.SetAuthenticationToken),
    map((action) => {
      this.tokenService.setAuthenticationToken(action.payload.authenticationToken);
    })
  ), { dispatch: false });

  /**
   * user registration
   *
   * @memberof AuthenticationEffects
   */

  register$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.Register>(AuthenticationActions.ActionTypes.Register),
    map(action => action),
    mergeMap((action) => this.userApi.registerByCredentials({ body: action.payload.registerData })
      .pipe(
        map((user: User) => {
          /* set password from local data */
          user.password = action.payload.registerData.password;
          /* register success */
          return new AuthenticationActions.RegisterSuccess({ user: user });
        }),
        catchError(response => {
          this.notificationService.createNotification(
            'error',
            'AUTHENTICATION_MODULE.NOTIFICATIONS.REGISTRATION_FAILED',
            response?.error?.error?.message
          );
          return [
            new AuthenticationActions.RegisterFailure(response.error.message)
          ];
        })
      )
    )
  ));

  /**
  * performs the login process after
  * the user registration is successful and redirects the user to the verification page
  *
  * @memberof AuthenticationEffects
  */

  registerSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.RegisterSuccess>(AuthenticationActions.ActionTypes.RegisterSuccess),
    withLatestFrom(
      this.routerStateService.getQueryParamByKey(QueryParam.RedirectUrl)
    ),
    concatMap(([action, redirectUrlQueryParam]) => {
      /* set redirect url */
      let redirectUrl = redirectUrlQueryParam;
      if (!redirectUrl) {
        /* set redirect url by username type */
        switch (action.payload.user.usernameType) {
          case UsernameType.Email:
            redirectUrl = AUTH_REDIRECTION_DEFINITIONS.LOGIN.LOGIN_WITH_EMAIL_VERIFY_REDIRECTION_PATH;
            break;
          case UsernameType.PhoneNumber:
            redirectUrl = AUTH_REDIRECTION_DEFINITIONS.LOGIN.LOGIN_WITH_PHONE_NUMBER_VERIFY_REDIRECTION_PATH;
            break;
          default:
            redirectUrl = PATH_DEFINITIONS.HOME_PAGE.HOME_PAGE_PATH;
            break;
        }
      }
      return [
        new RouterActions.AddQueryParam({ key: QueryParam.RedirectUrl, value: redirectUrl }),
        new AuthenticationActions.Login({
          username: action.payload.user?.username,
          password: action.payload.user?.password,
          loginType: LoginType.Register
        })
      ];
    }),
    catchError(error => [
      new AuthenticationActions.AuthenticationError({ errorOn: 'RegisterSuccess', error: error }),
      new AuthenticationActions.RegisterFailure({ error: error })
    ])
  ));


  login$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.Login>(AuthenticationActions.ActionTypes.Login),
    withLatestFrom(
      this.routerStateService.getQueryParamByKey(QueryParam.RedirectUrl)
    ),
    concatMap(([action, redirectUrl]) => {
      const credentials = {
        username: action.payload.username,
        password: action.payload.password,
      };
      return this.userApi
        .loginByCredentials({
          body: credentials
        })
        .pipe(
          concatMap((response: LoginResponseDto) => {
            /* define return actions */
            let actions: Action[] = [];

            /* get access token */
            const authenticationToken = response.authenticationToken;
            /* add redirect url if not exist ( set after login redirect page as redirect url ) */
            if (!redirectUrl) {
              actions.push(
                new RouterActions.AddQueryParam({
                  key: QueryParam.RedirectUrl,
                  value: AUTH_REDIRECTION_DEFINITIONS.LOGIN.LOGIN_REDIRECTION_PATH
                })
              );
            }
            actions = [
              ...actions,
              new AuthenticationActions.SetAuthenticationToken({ authenticationToken: authenticationToken }),
              new AuthenticationActions.LoginByAuthenticationToken({
                authenticationToken: authenticationToken,
                loginType: action.payload.loginType
              })
            ];
            return actions;
          }),
          catchError(httpErrorResponse => {
            const errorMessage = httpErrorResponse.status === 0
              ? 'An error occurred while logging in. Please try again later.'
              : httpErrorResponse.error?.error?.message;

            this.notificationService.createNotification(
              'error',
              'AUTHENTICATION_MODULE.NOTIFICATIONS.LOGIN_FAILED',
              errorMessage
            );
            return [
              new AuthenticationActions.AuthenticationError({ errorOn: 'Login', error: httpErrorResponse }),
              new AuthenticationActions.LoginFailure()
            ];
          })
        );
    })
  ));

  loginByAuthenticationToken$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.LoginByAuthenticationToken>(
      AuthenticationActions.ActionTypes.LoginByAuthenticationToken
    ),
    map(action => action),
    concatMap((action) => this.userApi
      .getUserByAuthenticationToken()
      .pipe(
        mergeMap((user: User) => [
          new AuthenticationActions.LoggedIn({
            user: user,
            loginType: action.payload.loginType,
            redirectUrl: action.payload.redirectUrl
          })
        ]),
        catchError(error => [
          new AuthenticationActions.LoginFailure(),
          new AuthenticationActions.AuthenticationError({
            errorOn: AuthenticationActions.ActionTypes.LoginByAuthenticationToken,
            error: error
          })
        ])
      )
    )
  ));

  loggedIn$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.LoggedIn>(AuthenticationActions.ActionTypes.LoggedIn),
    withLatestFrom(
      this.routerStateService.getQueryParamByKey(QueryParam.RedirectUrl)
    ),
    concatMap(([action, redirectUrl]) => {
      /* get user by parameter */
      const user = action.payload.user;

      const actions: Action[] = [
        new AuthenticationActions.SetUser({ user: user }),
        new AuthenticationActions.GetPermissions(),
        new AuthenticationActions.ChangeLastProcessedAction({
          lastProcessedAction: {
            success: true,
            action: AuthenticationActions.ActionTypes.LoggedIn,
          }
        }),
        new LoadComponentDefinitions(),
      ];

      if (!user?.people[0]?.interest) {
        actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROFILE.PROFILE_ONBOARDING_PATH }));
      } else if (!user?.people[0]?.isVerified) {
        // if person is not verified
        if (user?.usernameType === UsernameType.Email && user?.username) {
          // register & login with email
          actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.AUTH.ACCOUNT_VERIFICATION_PATH }));
        } else if (user?.usernameType === UsernameType.Email && !user?.username) {
          // register & login with social media and doesn't provide email
          // firstly fill the profile page
          actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROFILE.PROFILE_ONBOARDING_PATH }));
        }
      } else {
        /* if loggedin by logging in by the system then navigate */
        if (redirectUrl) {
          actions.push(new RouterActions.Navigate({ url: redirectUrl }));
        } else if (action.payload.redirectUrl) {
          actions.push(new RouterActions.Navigate({ url: action.payload.redirectUrl }));
        }
      }
      return actions;
    })
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType<
      AuthenticationActions.Logout
      | AuthenticationActions.LoginFailure
    >(
      AuthenticationActions.ActionTypes.Logout,
      AuthenticationActions.ActionTypes.LoginFailure
    ),
    concatMap((action) => {
      /* clear local storage */
      this.tokenService.clearAuthenticationToken();
      this.browserStorageManagementService.removeLocalStorageItem('activeProjectId');
      this.browserStorageManagementService.removeLocalStorageItem('activePersonId');
      /* clear state */
      return [
        new ActiveProjectActions.ClearActiveProject(),
        new AuthenticationActions.SetActivePerson({ person: null }),
        new AppActions.SetRightSidebarVisibility({ rightSidebarVisibility: false }),
        new AuthenticationActions.SetUser({ user: null }),
        new AuthenticationActions.SetTenant({ tenant: null }),
        new RouterActions.RemoveQueryParam({ key: QueryParam.RedirectUrl }), /* remove redirect url */
        new RouterActions.Navigate({ url: AUTH_REDIRECTION_DEFINITIONS.LOGOUT.LOGOUT_REDIRECTION_PATH }) /* redirect */
      ];
    })
  ));

  setUser$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SetUser>(
      AuthenticationActions.ActionTypes.SetUser
    ),
    mergeMap((action) => {
      /* get user by parameter */
      const user = action.payload.user;

      /* get active person */
      let activePerson = user?.people?.length === 1 ? user?.people[0] : null;
      if (!activePerson) {
        /* get active person id by storage */
        const activePersonId = this.browserStorageManagementService.getLocalStorageItem('activePersonId');
        if (activePersonId) {
          activePerson = user?.people?.find(person => person.id === activePersonId);
        }
      }

      if (activePerson) {
        /* change active person */
        return [new AuthenticationActions.ChangeActivePerson({ person: activePerson })];
      } else {
        return [new AuthenticationActions.ChangeActivePersonFailure({ error: 'Active person could not found', key: 'ChangeActivePersonFailure', timestamp: Date.now() })];
      }
    })
  ));


  changeActivePerson$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ChangeActivePerson>(AuthenticationActions.ActionTypes.ChangeActivePerson),
    mergeMap((action) => {

      const activePerson = action.payload.person;

      if (!activePerson) {
        return [
          new AuthenticationActions.AuthenticationError({
            errorOn: AuthenticationActions.ActionTypes.ChangeActivePerson,
            error: 'There is no person payload specified.'
          })
        ];
      }

      return this.userApi.changeActivePerson({ body: { personId: activePerson.id } }).pipe(
        mergeMap(response => {
          const authenticationToken = response.authenticationToken;
          return [
            new AuthenticationActions.SetAuthenticationToken({ authenticationToken: authenticationToken }),
            new AuthenticationActions.GetPermissions(),
            new AuthenticationActions.SetActivePerson({ person: activePerson }),
            new ProjectActions.GetProjects(),
            new ProjectActions.GetPublicProjectsPagination({ pageIndex: 1, pageSize: 8, searchText: '' }),
            new ProjectActions.GetPublicProjectsCategories(),
            new AuthenticationActions.ChangeActivePersonSuccessful({ person: activePerson })
          ];
        })
      );
    }),
    catchError(error => [
      new AuthenticationActions.AuthenticationError({
        errorOn: AuthenticationActions.ActionTypes.ChangeActivePerson,
        error: error
      })
    ])
  ));


  changeActivePersonSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ChangeActivePersonSuccessful>(AuthenticationActions.ActionTypes.ChangeActivePersonSuccessful),
    withLatestFrom(
      this.store.select(state => state.auth.user?.roleMapping?.role)
    ),
    mergeMap(([action, role]) => {
      const actions: Action[] = [];

      const activePerson = action.payload.person;
      const tenant = activePerson.tenant;

      /* set tenant */
      actions.push(new AuthenticationActions.GetTenant({ tenantId: activePerson.tenantId }));

      /* SET ACTIVE PROJECT STATE */
      let activeProjectId: string;
      /* set active project if there is only 1 project */
      if (activePerson.projects?.length === 1) {
        const activeProject = activePerson.projects[0];
        actions.push(new AuthenticationActions.ChangeActiveProject({ project: activeProject }));
      } else if (activePerson.projects?.length > 1) {
        /* set active project by selected active project id from localStorage if exist */
        activeProjectId = this.browserStorageManagementService.getLocalStorageItem('activeProjectId');
        const checkPersonHasProject = activeProjectId && activePerson.projects?.some(project => project.id === activeProjectId);
        if (checkPersonHasProject || role?.name === UserRoles.Admin) {
          actions.push(new ActiveProjectActions.GetActiveProject({ projectId: activeProjectId }));
        }
      } else {
        /* navigate to project list page to select any project */
        actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH }));
      }

      return actions;
    })
  ));


  changeActiveProject$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ChangeActiveProject>(
      AuthenticationActions.ActionTypes.ChangeActiveProject,
    ),
    withLatestFrom(this.store.select(state => state.activeProject.data)),
    mergeMap(([action, existingActiveProject]) => {
      if (existingActiveProject?.id !== action.payload.project?.id) {
        const activeProject = action.payload.project;

        if (!activeProject) {
          return [
            new AuthenticationActions.AuthenticationError({
              errorOn: AuthenticationActions.ActionTypes.ChangeActiveProject,
              error: 'There is no project payload specified.'
            })
          ];
        }
        const body = { projectId: activeProject.id };
        const api = action.payload.isAdmin ? this.userApi.changeActiveProjectByAdmin({ body }) : this.userApi.changeActiveProject({ body });

        return api.pipe(
          mergeMap(response => {
            const authenticationToken = response.authenticationToken;
            return [
              new AuthenticationActions.SetAuthenticationToken({ authenticationToken: authenticationToken }),
              new AuthenticationActions.ChangeActiveProjectSuccessful({
                project: activeProject,
                navigateToProjectDetail: action.payload.navigateToProjectDetail,
                navigateAIAssistantPage: action.payload.navigateAIAssistantPage
              }),
            ];
          })
        );
      } else {
        return [new AuthenticationActions.AuthenticationError({
          errorOn: AuthenticationActions.ActionTypes.ChangeActiveProject,
          error: 'Already on desired project.'
        })];
      }
    }),
    catchError(error => [
      new AuthenticationActions.AuthenticationError({
        errorOn: AuthenticationActions.ActionTypes.ChangeActiveProject,
        error: error
      })
    ])
  ));


  changeActiveProjectSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ChangeActiveProjectSuccessful>(
      AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful
    ),
    mergeMap((action) => [
      new ActiveProjectActions.ChangeActiveProjectVerified({
        project: action.payload.project,
        navigateToProjectDetail: action.payload.navigateToProjectDetail,
        navigateToAIAssistantPage: action.payload.navigateAIAssistantPage
      })
    ])
  ));


  setActivePerson$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SetActivePerson>(AuthenticationActions.ActionTypes.SetActivePerson),
    withLatestFrom(
      this.store.select(s => s.auth.user)
    ),
    mergeMap(([action, user]) => {
      /* actions result array */
      const actions: Action[] = [];

      /* get active person by parameter */
      const activePerson = action.payload.person;

      if (activePerson) {
        /* set activePersonId localStorage value */
        this.browserStorageManagementService.setLocalStorageItem('activePersonId', activePerson.id);
        if (!activePerson?.interest) {
          actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROFILE.PROFILE_ONBOARDING_PATH }));
        } else if (!activePerson?.isVerified) {
          // if person is not verified
          if (user?.usernameType === UsernameType.Email && user?.username) {
            // register & login with email
            actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.AUTH.ACCOUNT_VERIFICATION_PATH }));
          } else if (user?.usernameType === UsernameType.Email && !user?.username) {
            // register & login with social media and doesn't provide email
            // firstly fill the profile page
            actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROFILE.PROFILE_ONBOARDING_PATH }));
          }
        }
        actions.push(NotificationActions.GetNotifications());
      } else {
        actions.push(new ActiveProjectActions.ClearActiveProject());
        actions.push(new AuthenticationActions.SetTenant({ tenant: null }));
      }

      return actions;
    })
  ));


  getTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.GetTenant>(AuthenticationActions.ActionTypes.GetTenant),
      mergeMap((action) => {
        const tenantId = action.payload.tenantId;
        if (tenantId) {
          return this.tenantApi.findById({ id: tenantId, filter: { include: ['role'] } }).pipe(
            map((tenant: Tenant) => {
              return new AuthenticationActions.SetTenant({ tenant });
            })
          );
        } else {
          return [new AuthenticationActions.SetTenant({ tenant: null })];
        }
      })
    )
  );


  sendVerifySms$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SendVerifySms>(AuthenticationActions.ActionTypes.SendVerifySms),
    mergeMap((action) => this.verificationApi.sendPINtoVerifyPhoneNumber().pipe(
      map(() => new AuthenticationActions.SendVerifySmsSuccess())
    )), catchError(error => [
      new AuthenticationActions.AuthenticationError({ errorOn: 'SendVerifySms', error: error }),
      new AuthenticationActions.SendVerifySmsFailure()
    ])
  ));


  sendVerifySmsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SendVerifySmsSuccess>(AuthenticationActions.ActionTypes.SendVerifySmsSuccess),
    map((action) => {
      this.notificationService.createNotification('success', 'SHARED.SUCCESSFULLY', 'AUTHENTICATION_MODULE.NOTIFICATIONS.WE_SENT_VERIFICATION_SMS');
      this.router.navigateByUrl(AUTH_REDIRECTION_DEFINITIONS.LOGIN.LOGIN_WITH_PHONE_NUMBER_VERIFY_REDIRECTION_PATH);
    })
  ), { dispatch: false });


  sendVerifySmsFailure$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SendVerifySmsFailure>(AuthenticationActions.ActionTypes.SendVerifySmsFailure),
    map(() => {
      this.notificationService.createNotification('error', 'SHARED.ERROR', 'AUTHENTICATION_MODULE.NOTIFICATIONS.WE_COULDNOT_SEND_VERIFICATION_SMS');
    })
  ), { dispatch: false });


  sendVerifyEmail$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SendVerifyEmail>(AuthenticationActions.ActionTypes.SendVerifyEmail),
    mergeMap(() => this.verificationTokenApi.sendTokenToVerifyEmail().pipe(
      map(response => {
        if (response.success) {
          return new AuthenticationActions.SendVerifyEmailSuccess();
        }
      })
    )), catchError(error => [
      new AuthenticationActions.AuthenticationError({ errorOn: 'SendVerifyEmail', error: error }),
      new AuthenticationActions.SendVerifyEmailFailure()
    ])
  ));


  sendVerifyEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SendVerifyEmailSuccess>(AuthenticationActions.ActionTypes.SendVerifyEmailSuccess),
    map(() => {
      this.notificationService.createNotification('success', 'AUTHENTICATION_MODULE.NOTIFICATIONS.WE_SENT_VERIFICATION_EMAIL', '');
    })
  ), { dispatch: false });


  sendVerifyEmailFailure$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.SendVerifyEmailFailure>(AuthenticationActions.ActionTypes.SendVerifyEmailFailure),
    map(() => {
      this.notificationService.createNotification('error', 'SHARED.ERROR', 'AUTHENTICATION_MODULE.NOTIFICATIONS.WE_COULDNOT_SEND_VERIFICATION_EMAIL');
    })
  ), { dispatch: false });

  /**
  * Get Person Project Roles
  *
  * @memberof ProjectRoleEffects
  */

  getPersonProjectRoles$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.GetPersonProjectRoles>(
      AuthenticationActions.ActionTypes.GetPersonProjectRoles
    ),
    mergeMap((action) => this.personApi.getProjectRoles().pipe(
      mergeMap((personProjectRoles: ProjectRole[]) => [
        new AuthenticationActions.SetPersonProjectRoles({ roles: personProjectRoles })
      ])
    )),
    catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_COULDNOT_LAODED'
      );
      return [
        new AuthenticationActions.AuthenticationError({ errorOn: 'GetPersonProjectRoles', error: error }),
      ];
    })
  ));

  getPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.GetPermissions>(AuthenticationActions.ActionTypes.GetPermissions),
      mergeMap(() =>
        this.accessTokenService.decodeJWT().pipe(
          map((response) => new AuthenticationActions.GetPermissionsSuccessful({ permissions: response.scope })),
          catchError((error) => {
            return [new AuthenticationActions.GetPermissionsFailure({ errorMessage: error.message })];
          })
        )
      )
    )
  );

  getPermissionsSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.GetPermissionsSuccessful>(AuthenticationActions.ActionTypes.GetPermissionsSuccessful),
      map((action) => new AuthenticationActions.SetPermissions({ permissions: action.payload.permissions }))
    )
  );

  getPermissionsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.GetPermissionsFailure>(AuthenticationActions.ActionTypes.GetPermissionsFailure),
      map((action: AuthenticationActions.GetPermissionsFailure) => {
        this.notificationService.createNotification(
          'error',
          'Error',
          action.payload?.errorMessage ?? 'An error occurred while retrieving permissions'
        );
      })
    ),
    { dispatch: false }
  );

  /**
  * Create Project Role and update state fields
  *
  * @memberof ProjectRoleEffects
  */

  getActivePerson$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.GetActivePerson>(
      AuthenticationActions.ActionTypes.GetActivePerson
    ),
    mergeMap((action) => {
      /* get person id by parameter */
      const personId = action.payload.personId;
      if (personId) {
        return this.personApi.findById({ id: personId }).pipe(
          mergeMap((person: Person) =>
            /* set active person */
            [
              new AuthenticationActions.SetActivePerson({ person: person })
            ]
          )
        );
      } else {
        /* do nothing */
        return [new AuthenticationActions.SetActivePersonFailure({ error: 'personId could not found.', key: 'SetActivePersonFailure', timestamp: Date.now() })];
      }
    }),
    catchError(error => [
      new AuthenticationActions.AuthenticationError({ errorOn: AuthenticationActions.ActionTypes.GetActivePerson, error: error })
    ])
  ));

  /**
   * Verify Email Effect
   *
   * @memberof AuthenticationEffects
   */

  verifyEmail$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.VerifyEmail>(AuthenticationActions.ActionTypes.VerifyEmail),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson?.id),
    ),
    mergeMap(([action, activePersonId]) => {
      const personId = activePersonId || this.browserStorageManagementService.getLocalStorageItem('activePersonId');
      return this.personApi.verify({ token: action.payload.token }).pipe(
        mergeMap(() => {
          this.notificationService.createNotification('success', 'Your email is verified', '');
          return [
            new AuthenticationActions.GetActivePerson({ personId: personId }),
            new AuthenticationActions.ChangeLastProcessedAction({
              lastProcessedAction: {
                success: true,
                action: AuthenticationActions.ActionTypes.VerifyEmail,
                message: 'Email has been verified successfully.'
              }
            }),
            new AuthenticationActions.VerifyEmailSuccessful()
          ];
        }
        )
      );
    }),
    catchError(error => {
      this.notificationService.createNotification('error', 'Something is wrong', error?.error?.error?.message);
      return [
        new AuthenticationActions.AuthenticationError({ errorOn: AuthenticationActions.ActionTypes.VerifyEmail, error: error }),
        new AuthenticationActions.ChangeLastProcessedAction({
          lastProcessedAction: {
            success: true,
            action: AuthenticationActions.ActionTypes.VerifyEmail,
            message: 'Email could not be verified.',
            data: null
          }
        })
      ];
    })
  ));


  verifyEmailSuccessfulAndNavigate$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.VerifyEmailSuccessful>(AuthenticationActions.ActionTypes.VerifyEmailSuccessful),
    delay(delayTimeForVerifyEmailSuccessfulPage.delayTime),
    map(() => new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH })
    )
  ));

  /**
   * Verify Phone Number Effect
   *
   * @memberof AuthenticationEffects
   */

  verifyPhoneNumber$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.VerifyPhoneNumber>(AuthenticationActions.ActionTypes.VerifyPhoneNumber),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson?.id),
    ),
    mergeMap(([action, activePersonId]) => {
      const personId = activePersonId || this.browserStorageManagementService.getLocalStorageItem('activePersonId');
      const pin = action.payload.pin;
      return this.personApi.verify({ token: pin }).pipe(
        mergeMap(() => [
          new AuthenticationActions.GetActivePerson({ personId: personId }),
          new AuthenticationActions.ChangeLastProcessedAction({
            lastProcessedAction: {
              success: true,
              action: AuthenticationActions.ActionTypes.VerifyPhoneNumber,
              message: 'Phone number has been verified successfully.'
            }
          })
        ]
        )
      );
    }),
    catchError(error => [
      new AuthenticationActions.AuthenticationError({
        errorOn: AuthenticationActions.ActionTypes.VerifyPhoneNumber,
        error: error
      }),
      new AuthenticationActions.ChangeLastProcessedAction({
        lastProcessedAction: {
          success: true,
          action: AuthenticationActions.ActionTypes.VerifyPhoneNumber,
          message: 'Phone number could not be verified.',
          data: null
        }
      })
    ])
  ));


  forgotPassword$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ForgotPassword>(AuthenticationActions.ActionTypes.ForgotPassword),
    mergeMap((action) => {
      const body = { username: action.payload.username };
      return this.userApi.forgotPassword(body).pipe(
        map(() => new AuthenticationActions.ForgotPasswordSuccessful()),
        catchError((error) => [
          new AuthenticationActions.ForgotPasswordFailure({ error: error })
        ])
      );
    })
  ));


  forgotPasswordSuccessful = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ForgotPasswordSuccessful>(
      AuthenticationActions.ActionTypes.ForgotPasswordSuccessful
    ),
    map(() => new RouterActions.Navigate({ url: PATH_DEFINITIONS.AUTH.FORGOT_PASSWORD_SUCCESS_PATH }))
  ));


  forgotPasswordFailure = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ForgotPasswordFailure>
      (AuthenticationActions.ActionTypes.ForgotPasswordFailure),
    map((action) => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.ERROR'
      );
      return new AuthenticationActions.ErrorAction({ error: action.payload.error, key: 'ForgotPasswordFailure' });
    })
  ));


  changePassword$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ChangePassword>(AuthenticationActions.ActionTypes.ChangePassword),
    mergeMap((action) => {
      const params = {
        body: {
          newPassword: action.payload.password,
          token: action.payload.token
        }
      };
      return this.userApi.changePasswordByVerificationToken(params).pipe(
        map(() => new AuthenticationActions.ChangePasswordSuccessful()),
        catchError((error) => [
          new AuthenticationActions.ChangePasswordFailure({ error: error })
        ])
      );
    })
  ));


  changePasswordSuccessful = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ChangePasswordSuccessful>
      (AuthenticationActions.ActionTypes.ChangePasswordSuccessful),
    map(() => new RouterActions.Navigate({ url: PATH_DEFINITIONS.AUTH.CHANGE_PASSWORD_SUCCESS_PATH }))
  ));


  changePasswordFailure = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.ChangePasswordFailure>
      (AuthenticationActions.ActionTypes.ChangePasswordFailure),
    map((action) => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.ERROR'
      );
      return new AuthenticationActions.ErrorAction({ error: action.payload.error, key: 'ChangePasswordFailure' });
    })
  ));


  acceptInvitationByToken$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.AcceptInvitationByToken>
      (AuthenticationActions.ActionTypes.AcceptInvitationByToken),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson)
    ),
    delay(1000),
    mergeMap(([action, activePerson]) => {
      if (activePerson) {
        if (action.payload.invitationToken) {
          return this.personInvitationApi.acceptInvitationByToken({ token: action.payload.invitationToken }).pipe(
            map(() => new AuthenticationActions.AcceptInvitationByTokenSuccessful()),
            catchError((error) => [
              new AuthenticationActions.AcceptInvitationByTokenFailure({ error })
            ])
          );
        } else {
          return [
            new AuthenticationActions.AcceptInvitationByTokenFailure({ error: 'Invitation token not found.' })
          ];
        }
      } else if (this.count === 5) {
        return [
          new AuthenticationActions.AcceptInvitationByTokenFailure({ error: 'Invitation token not found.' })
        ];
      } else {
        this.count++;
        return [
          new AuthenticationActions.AcceptInvitationByToken({ invitationToken: action.payload.invitationToken })
        ];
      }
    }), catchError((error) => [
      new AuthenticationActions.AcceptInvitationByTokenFailure({ error })
    ])
  ));


  acceptInvitationByTokenSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.AcceptInvitationByTokenSuccessful>
      (AuthenticationActions.ActionTypes.AcceptInvitationByTokenSuccessful),
    map((action) => {
      localStorage.removeItem('invitationToken');
      this.notificationService.createNotification(
        'success',
        'SHARED.SUCCESSFUL',
        'You are added to project successfully.'
      );

      return new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH });
    })
  ));


  acceptInvitationByTokenFailure$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.AcceptInvitationByTokenFailure>
      (AuthenticationActions.ActionTypes.AcceptInvitationByTokenFailure),
    map((action) => {
      localStorage.removeItem('invitationToken');
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'There was an error while adding you to project.'
      );
      return new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH });
    })
  ));


  updatePreferredThemeForActiveUser$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.UpdatePreferredThemeForActiveUser>
      (AuthenticationActions.ActionTypes.UpdatePreferredThemeForActiveUser),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson?.id),
    ),
    mergeMap(([action, activePersonId]) => this.personApi.updateById({ id: activePersonId, body: { preferredTheme: action.payload.preferredTheme } }).pipe(
      mergeMap(() => [new AuthenticationActions.UpdatePreferredThemeForActiveUserSuccessful({ preferredTheme: action.payload.preferredTheme })]),
      catchError(error => [
        new AuthenticationActions.ErrorAction({ error: error, key: 'UpdatePreferredThemeForActiveUser', timestamp: Date.now() })
      ])
    ))
  ));


  updatePerson$ = createEffect(() => this.actions$.pipe(
    ofType<AuthenticationActions.UpdatePerson>
      (AuthenticationActions.ActionTypes.UpdatePerson),
    mergeMap(action => this.personApi.updateById({ id: action.payload.id, body: action.payload.person }).pipe(
      mergeMap((updatedPerson: Person) => {
        const actions = [
          new AuthenticationActions.UpdatePersonSuccessful({ person: updatedPerson, id: updatedPerson.id })
        ] as Action[];
        if (action.payload.navigateToProjectList) {
          actions.push(new RouterActions.Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH }));
        }
        this.notificationService.createNotification('success', 'Your profile has been updated successfully.', '');
        return actions;
      }),
      catchError(error => {
        const errorMessage = error?.error?.error?.message;
        this.notificationService.createNotification('warning', 'Profile Update Error', errorMessage);
        return [
          new AuthenticationActions.ErrorAction({ error, key: 'UpdatePersonFailure', timestamp: Date.now() })
        ];
      })
    ))
  ));

}
