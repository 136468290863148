import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DataTransformationService, NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { DataTransformationControllerService, UiWorkflowStepFunctionControllerService, UiWorkflowStepFunctionPartial, UiWorkflowStepFunctionPublishedEventOnSuccessControllerService, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from 'libs/shared/src/lib/definitions/path-definition';
import { WorkflowItemInDiagram } from 'libs/diagram-editor/src/lib/utils/workflow-item-in-diagram.interface';
import { DiagramItemType } from 'libs/diagram-editor/src/lib/utils/diagram-item-type';
import { GetUIStepFunctionWithDetailsFilter } from './utils/get-ui-step-function-with-details-filter';
import { UIDataStoreIdForTemplateWorkflows } from '../../../../../workflow-templates/src/lib/components/workflow-templates-pages/utils/get-ui-data-store-with-filtered-id.enum';
import { Router } from '@angular/router';
import { FullPaths } from '@rappider/shared/definitions';
import { QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';

import * as UIWorkflowStepFunctionActions from './ui-step-function.actions';
import * as DiagramActions from 'libs/diagram-editor/src/lib/state/diagram-state/diagram.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import { CreateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful, DeleteUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful, UpdateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful } from './ui-step-function.actions';

@Injectable()
export class UIWorkflowStepFunctionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private UIWorkflowStepFunctionApi: UiWorkflowStepFunctionControllerService,
    private dataTransformationApi: DataTransformationControllerService,
    private dataTransformationService: DataTransformationService,
    private router: Router,
    private uiWorkflowStepFunctionPublishedEventOnSuccessApi: UiWorkflowStepFunctionPublishedEventOnSuccessControllerService
  ) { }


  getUIStepFunctions$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctions>(
      UIWorkflowStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctions
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id),
      this.store.select(state => state.uiDataStore?.data)
    ),
    mergeMap(([action, activeProjectId, uiDataStores]) => {
      if (activeProjectId && uiDataStores?.length) {
        const params = {
          filter: {
            where: { uiDataStoreId: { inq: uiDataStores.map(uiDataStore => uiDataStore.id) } },
            include: GetUIStepFunctionWithDetailsFilter
          }
        };
        return this.UIWorkflowStepFunctionApi.find(params).pipe(
          mergeMap((uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[]) =>
            [(new UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionsSuccessful(
              { uiWorkflowStepFunctions: uiWorkflowStepFunctions }))]
          )
        );
      } else {
        return [
          new UIWorkflowStepFunctionActions.ErrorAction({
            error: 'NoActiveProject',
            key: 'GetUIWorkflowStepFunctions', timestamp: Date.now()
          })
        ];
      }
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [
        new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'GetUIWorkflowStepFunctions', timestamp: Date.now() })
      ];
    })
  ));

  getUIWorkflowStepFunctionPublishedEventOnSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctions>(
      UIWorkflowStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctions
    ),
    mergeMap(() => {
      return this.uiWorkflowStepFunctionPublishedEventOnSuccessApi.find().pipe(
        map((uiWorkflowStepFunctionPublishedEventOnSuccess) => {
          return new UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ uiWorkflowStepFunctionPublishedEventOnSuccess })
        })
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [
        new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'GetUIWorkflowStepFunctions', timestamp: Date.now() })
      ];
    })
  ));


  getUIStepFunctionsAccordingToIds$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionsAccordingToId>(
      UIWorkflowStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctionsAccordingToId
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          where: {
            id: { inq: action.payload.uiWorkflowStepFunctionsIds }
          },
          include: GetUIStepFunctionWithDetailsFilter
        }
      };
      return this.UIWorkflowStepFunctionApi.find(params).pipe(
        mergeMap((uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[]) => {
          const activeItemForDiagram: WorkflowItemInDiagram = {
            item: uiWorkflowStepFunctions[0],
            type: DiagramItemType.UIStepFunction
          };
          const actionsToDispatch: Action[] = [
            new UIWorkflowStepFunctionActions.BulkCreateUIWorkflowStepFunctionSuccessful({ uiWorkflowStepFunctions: uiWorkflowStepFunctions }),
          ];

          if (this.router.url.includes(FullPaths.DiagramEditor)) {
            actionsToDispatch.push(DiagramActions.SetActiveDiagramItem({ payload: { activeItem: activeItemForDiagram } }));
          }
          return actionsToDispatch;
        })
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [new UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionsAccordingToIdFailure({ error, key: 'GetUIWorkflowStepFunctionsAccordingToIdFailure', timestamp: Date.now() })];
    })
  ));


  getUIStepFunctionById$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionById>(
      UIWorkflowStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctionById
    ),
    mergeMap((action) => {
      const params = {
        id: action.payload.uiWorkflowStepFunctionId,
        filter: {
          include: GetUIStepFunctionWithDetailsFilter
        }
      };
      return this.UIWorkflowStepFunctionApi.findById(params).pipe(
        mergeMap((uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations) => {
          const actionsToDispatch: Action[] = [
            new UIWorkflowStepFunctionActions.CreateUIWorkflowStepFunctionSuccessful({ uiWorkflowStepFunction })
          ];
          return actionsToDispatch;
        })
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [
        new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'GetUIWorkflowStepFunctions', timestamp: Date.now() })
      ];
    })
  ));

  /**
  * This effect triggers when project model created with flag generateUIDataStore set to true
  *
  * @memberof UIWorkflowStepFunctionEffects
  */

  getUIStepFunctionsAccordingToUIDataStoreIds$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionsAccordingToUIDataStoreId>(
      UIWorkflowStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctionsAccordingToUIDataStoreId
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          where: {
            uiDataStoreId: action.payload.uiDataStoreId
          },
          include: GetUIStepFunctionWithDetailsFilter
        }
      };
      return this.UIWorkflowStepFunctionApi.find(params).pipe(
        map((uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[]) =>
          new UIWorkflowStepFunctionActions.BulkCreateUIWorkflowStepFunctionSuccessful({ uiWorkflowStepFunctions: uiWorkflowStepFunctions })
        )
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [new UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionsAccordingToUIDataStoreIdFailure({ error, key: 'GetUIWorkflowStepFunctionsAccordingToUIDataStoreIdFailure', timestamp: Date.now() })];
    })
  ));


  createUIWorkflowStepFunction$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.CreateUIWorkflowStepFunction>(
      UIWorkflowStepFunctionActions.ActionTypes.CreateUIWorkflowStepFunction
    ),
    mergeMap((action) => this.UIWorkflowStepFunctionApi.create({ body: action.payload.uiWorkflowStepFunction }).pipe(
      mergeMap((uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations) => {
        this.notificationService.createNotification(
          'success',
          'SHARED.SUCCESSFUL',
          `${uiWorkflowStepFunction.name} Successfully Created`
        );
        const publishedActions: Action[] = [
          new UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionById({ uiWorkflowStepFunctionId: uiWorkflowStepFunction.id })
        ];

        if (action.payload.navigateAfterCreate) {
          this.router.navigate([`${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}`, uiWorkflowStepFunction.uiDataStoreId], { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.UI_STEP_FUNCTIONS_TAB });
        }
        return publishedActions;
      }), catchError(error => {
        this.notificationService.createNotification(
          'error',
          action.payload.uiWorkflowStepFunction.name,
          'SHARED.COULDNT_CREATED'
        );
        return [new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'CreateUIWorkflowStepFunction', timestamp: Date.now() })];
      })
    ))
  ));


  updateUIWorkflowStepFunction$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.UpdateUIWorkflowStepFunction>(
      UIWorkflowStepFunctionActions.ActionTypes.UpdateUIWorkflowStepFunction
    ),
    withLatestFrom(this.store.select(state => state.uiDataEvent.data), this.store.select(state => state.uiWorkflowStepFunction.data)),
    mergeMap(([action, uiDataEvents, uiStepFunctions]) => {
      const updateBody = {
        id: action.payload.uiWorkflowStepFunction.id,
        body: action.payload.uiWorkflowStepFunction as UiWorkflowStepFunctionPartial
      };
      return this.UIWorkflowStepFunctionApi.customUpdateById(updateBody).pipe(
        mergeMap(() => {
          const uiStepFunctionPayload = action.payload.uiWorkflowStepFunction;
          const updatingUIStepFunction = uiStepFunctions.find(stepFunction => stepFunction.id === uiStepFunctionPayload.id);

          const uiWorkflowStepFunction = {
            ...uiStepFunctionPayload,
            subscribedEvents: updatingUIStepFunction.subscribedEvents,
            publishedEventsOnSuccess: updatingUIStepFunction.publishedEventsOnSuccess,
            publishedEventsOnFailure: updatingUIStepFunction.publishedEventsOnFailure,
            uiDataStoreId: action.payload.uiDataStoreId
          };
          this.notificationService.createNotification(
            'success',
            action.payload.uiWorkflowStepFunction.name,
            'SHARED.SUCCESSFULLY_UPDATED'
          );
          const publishedActions: Action[] = [
            new UIWorkflowStepFunctionActions.UpdateUIWorkflowStepFunctionSuccessful({
              uiWorkflowStepFunction: uiWorkflowStepFunction
            })
          ];
          let publishedSuccessWithConditionData;
          if (action.payload.uiWorkflowStepFunction.addedPublishedEventsOnSuccessWithCondition.length) {
            publishedSuccessWithConditionData = action.payload.uiWorkflowStepFunction.addedPublishedEventsOnSuccessWithCondition
            publishedActions.push(new CreateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ uiWorkflowStepFunctionPublishedEventOnSuccess: publishedSuccessWithConditionData[0] }))
          } else if (action.payload.uiWorkflowStepFunction.updatedPublishedEventsOnSuccessWithCondition.length) {
            publishedSuccessWithConditionData = action.payload.uiWorkflowStepFunction.updatedPublishedEventsOnSuccessWithCondition
            publishedActions.push(new UpdateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ uiWorkflowStepFunctionPublishedEventOnSuccess: publishedSuccessWithConditionData[0] }))
          } else if (action.payload.uiWorkflowStepFunction.deletedPublishedEventOnSuccessIds.length) {
            publishedSuccessWithConditionData = action.payload.uiWorkflowStepFunction.deletedPublishedEventOnSuccessIds
            publishedSuccessWithConditionData.forEach((id: string) => {
                publishedActions.push(new DeleteUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ id }));
            });
          }
          if (action.payload.navigateAfterUpdate) {
            this.router.navigate([`${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}`, uiWorkflowStepFunction.uiDataStoreId], { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.UI_STEP_FUNCTIONS_TAB });
          }
          return publishedActions;
        }), catchError(err => {
          this.notificationService.createNotification(
            'error',
            action.payload.uiWorkflowStepFunction.name,
            'SHARED.COULDNT_UPDATED'
          );
          return [new UIWorkflowStepFunctionActions.ErrorAction({ error: err, key: 'UpdateUIWorkflowStepFunction', timestamp: Date.now() })];
        })
      );
    })
  ));


  updateUIStepFunctionSuccessful = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.UpdateUIWorkflowStepFunctionSuccessful>(
      UIWorkflowStepFunctionActions.ActionTypes.UpdateUIWorkflowStepFunctionSuccessful
    ),
    map((action) => new UIWorkflowStepFunctionActions.GetUpdatedUIWorkflowStepFunctionWithDetails({ uiWorkflowStepFunction: action.payload.uiWorkflowStepFunction }))
  ));

  /**
   * gets updated ui step function with details after a successful update
   *
   * @memberof UIWorkflowStepFunctionEffects
   */

  getUpdatedUIWorkflowStepFunctionWithDetails = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetUpdatedUIWorkflowStepFunctionWithDetails>(
      UIWorkflowStepFunctionActions.ActionTypes.GetUpdatedUIWorkflowStepFunctionWithDetails
    ),
    mergeMap((action) => {
      const filter = { include: GetUIStepFunctionWithDetailsFilter };

      return this.UIWorkflowStepFunctionApi.findById({ id: action.payload.uiWorkflowStepFunction.id, filter }).pipe(
        mergeMap((uiStepFunction) => {
          const updatedUIStepFunction: WorkflowItemInDiagram = {
            item: uiStepFunction,
            type: DiagramItemType.UIStepFunction
          };

          return [
            new UIWorkflowStepFunctionActions.GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful({ uiWorkflowStepFunction: uiStepFunction }),
            DiagramActions.SetActiveDiagramItem({ payload: { activeItem: updatedUIStepFunction } }),
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            action.payload.uiWorkflowStepFunction.name,
            'SHARED.COULDNT_UPDATED'
          );
          return [new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'RefreshUpdatedUIWorkflowStepFunction', timestamp: Date.now() })];
        })
      );
    })
  ));


  deleteUIWorkflowStepFunction$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.DeleteUIWorkflowStepFunction>(
      UIWorkflowStepFunctionActions.ActionTypes.DeleteUIWorkflowStepFunction
    ),
    mergeMap((action) => this.UIWorkflowStepFunctionApi.deleteById({ id: action.payload.uiWorkflowStepFunction.id }).pipe(
      map(() => {
        this.notificationService.createNotification(
          'success',
          action.payload.uiWorkflowStepFunction.name,
          'SHARED.SUCCESSFULLY_DELETED'
        );

        return new UIWorkflowStepFunctionActions.DeleteUIWorkflowStepFunctionSuccessful({ id: action.payload.uiWorkflowStepFunction.id });
      }), catchError(err => {
        this.notificationService.createNotification(
          'error',
          action.payload.uiWorkflowStepFunction.name,
          'SHARED.COULDNT_DELETED'
        );
        return [new UIWorkflowStepFunctionActions.ErrorAction({ error: err, key: 'UpdateUIWorkflowStepFunction', timestamp: Date.now() })];
      })
    ))
  ));

  /* Pre Data Transformation */

  getPreDataTransformationData$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetPreDataTransformationData>(
      UIWorkflowStepFunctionActions.ActionTypes.GetPreDataTransformationData
    ),
    mergeMap((action) => {
      const uiStepFunction = action.payload.uiStepFunction;
      if (uiStepFunction.preDataTransformationId) {
        return [
          new UIWorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaData({ uiStepFunction })
        ];
      } else {
        return [
          new UIWorkflowStepFunctionActions.CreatePreDataTransformationData({ uiStepFunction })
        ];
      }
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.GetPreDataTransformationDataFailure({ error, key: 'GetPreDataTransformationData', timestamp: Date.now() })
    ]))
  ));


  createPreDataTransformationData$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.CreatePreDataTransformationData>(
      UIWorkflowStepFunctionActions.ActionTypes.CreatePreDataTransformationData
    ),
    mergeMap((action) => {
      const body = {
        inputVariableName: 'input',
        outputVariableName: 'output'
      };

      return this.dataTransformationApi.create({ body }).pipe(
        map((preDataTransformation) => new UIWorkflowStepFunctionActions.CreatePreDataTransformationDataSuccessful({
          preDataTransformationId: preDataTransformation.id,
          uiStepFunction: action.payload.uiStepFunction
        }))
      );
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.CreatePreDataTransformationDataFailure({ error, key: 'CreatePreDataTransformationData', timestamp: Date.now() })
    ]))
  ));

  /**
   * update ui workflow step function after successfully creating DT
   *
   * @memberof UIWorkflowStepFunctionEffects
   */

  createPreDataTransformationDataSucessful$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.CreatePreDataTransformationDataSuccessful>(
      UIWorkflowStepFunctionActions.ActionTypes.CreatePreDataTransformationDataSuccessful
    ),
    mergeMap((action) => {
      const uiStepFunction = action.payload.uiStepFunction;
      const preDataTransformationId = action.payload.preDataTransformationId;

      return this.UIWorkflowStepFunctionApi.customUpdateById({
        id: uiStepFunction.id,
        body: { preDataTransformationId: preDataTransformationId }
      }).pipe(
        map(() => {
          const uiStepFunctionWithPreDTId = {
            ...uiStepFunction,
            preDataTransformationId: preDataTransformationId
          };
          return new UIWorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaData({ uiStepFunction: uiStepFunctionWithPreDTId });
        })
      );
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.ErrorAction({ error, key: 'UpdataUIStepFunctionAfterCreatePreDT', timestamp: Date.now() })
    ]))
  ));


  getPreDTSourceAndTargetSchemaData$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaData>(
      UIWorkflowStepFunctionActions.ActionTypes.GetPreDTSourceAndTargetSchemaData
    ),
    withLatestFrom(
      this.store.select(state => state.projectModelEndpoint?.data),
      this.store.select(state => state.workflowStepFunction?.data),
      this.store.select(state => state.workflowEvent?.data),
      this.store.select(state => state.customFunction?.data),
      this.store.select(state => state.workflowStepFunction?.templateWorkflowStepFunctions),
      this.store.select(state => state.customFunction?.templateWorkflowFunctions),
    ),
    mergeMap(([
      action,
      projectModelEndpoints,
      workflowStepFunctions,
      workflowEvents,
      customFunctions,
      templateWorkflowStepFunctions,
      templateWorkflowFunctions,
    ]) => {
      const uiStepFunction = action.payload.uiStepFunction;

      return this.dataTransformationService.getPreDataTransformationSourceAndTargetSchemas(
        uiStepFunction,
        projectModelEndpoints,
        workflowStepFunctions,
        workflowEvents,
        customFunctions,
        templateWorkflowStepFunctions,
        templateWorkflowFunctions
      ).pipe(
        mergeMap((sourceAndTargetSchema) => [
          new UIWorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaDataSuccessful({
            preDataTransformationId: uiStepFunction.preDataTransformationId,
            preSourceJsonSchema: sourceAndTargetSchema.sourceSchema,
            preTargetJsonSchema: sourceAndTargetSchema.targetSchema
          })
        ])
      );
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaDataFailure({ error, key: 'GetPreDTSourceAndTargetSchemaData', timestamp: Date.now() })
    ]))
  ));

  /**
   * end get pre dt data process after getting source schema, target schema, and dt id
   *
   * @memberof UIWorkflowStepFunctionEffects
   */

  getPreDTSourceAndTargetSchemaDataSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaDataSuccessful>(
      UIWorkflowStepFunctionActions.ActionTypes.GetPreDTSourceAndTargetSchemaDataSuccessful
    ),
    mergeMap((action) => [
      new UIWorkflowStepFunctionActions.GetPreDataTransformationDataSuccessful({
        preDataTransformationId: action.payload.preDataTransformationId,
        sourceJsonSchema: action.payload.preSourceJsonSchema,
        targetJsonSchema: action.payload.preTargetJsonSchema
      })
    ]), catchError((error) => ([
      new UIWorkflowStepFunctionActions.ErrorAction({ error, key: 'GetPreDataTransformationDataFailure', timestamp: Date.now() })
    ]))
  ));


  getPostDataTransformationData$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetPostDataTransformationData>(
      UIWorkflowStepFunctionActions.ActionTypes.GetPostDataTransformationData
    ),
    mergeMap((action) => {
      const uiStepFunction = action.payload.uiStepFunction;

      /* check if target schema(publishedEventsOnSuccess) exists in ui step function */
      if (uiStepFunction.publishedEventsOnSuccess?.length) {
        if (uiStepFunction.postDataTransformationId) {
          return [
            new UIWorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaData({ uiStepFunction })
          ];
        } else {
          return [
            new UIWorkflowStepFunctionActions.CreatePostDataTransformationData({ uiStepFunction })
          ];
        }
      } else {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'A published event is required to add post-data transformation.'
        );

        return [
          new UIWorkflowStepFunctionActions.ErrorAction({
            error: 'PublishedEventIsNeededForPostDt',
            key: 'PublishedEventIsNeededForPostDt',
            timestamp: Date.now()
          })
        ];
      }
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaDataFailure({ error, key: 'GetPostDataTransformationData', timestamp: Date.now() })
    ]))
  ));


  createPostDataTransformationData$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.CreatePostDataTransformationData>(
      UIWorkflowStepFunctionActions.ActionTypes.CreatePostDataTransformationData
    ),
    mergeMap((action) => {
      const body = {
        inputVariableName: 'input',
        outputVariableName: 'output'
      };

      return this.dataTransformationApi.create({ body }).pipe(
        map((postDataTransformation) => new UIWorkflowStepFunctionActions.CreatePostDataTransformationDataSuccessful({
          postDataTransformationId: postDataTransformation.id,
          uiStepFunction: action.payload.uiStepFunction
        }))
      );
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.CreatePostDataTransformationDataFailure({ error, key: 'CreatePostDataTransformationData', timestamp: Date.now() })
    ]))
  ));

  /**
   * update ui workflow step function after successfully creating DT
   *
   * @memberof UIWorkflowStepFunctionEffects
   */

  createPostDataTransformationDataSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.CreatePostDataTransformationDataSuccessful>(
      UIWorkflowStepFunctionActions.ActionTypes.CreatePostDataTransformationDataSuccessful
    ),
    mergeMap((action) => {
      const uiStepFunction = action.payload.uiStepFunction;
      const postDataTransformationId = action.payload.postDataTransformationId;

      return this.UIWorkflowStepFunctionApi.customUpdateById({
        id: uiStepFunction.id,
        body: { postDataTransformationId: postDataTransformationId }
      }).pipe(
        map(() => {
          const uiStepFunctionWithPostDTId = {
            ...uiStepFunction,
            postDataTransformationId: postDataTransformationId
          };
          return new UIWorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaData({ uiStepFunction: uiStepFunctionWithPostDTId });
        })
      );
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.ErrorAction({ error, key: 'UpdateUIStepFunctionAfterCreatingPostDT', timestamp: Date.now() })
    ]))
  ));


  getPostDTSourceAndTargetSchemaData$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaData>(
      UIWorkflowStepFunctionActions.ActionTypes.GetPostDTSourceAndTargetSchemaData
    ),
    withLatestFrom(
      this.store.select(state => state.projectModelEndpoint?.data),
      this.store.select(state => state.workflowStepFunction?.data),
      this.store.select(state => state.workflowEvent?.data),
      this.store.select(state => state.customFunction?.data),
      this.store.select(state => state.workflowStepFunction?.templateWorkflowStepFunctions),
      this.store.select(state => state.customFunction?.templateWorkflowFunctions),
    ),
    mergeMap(([
      action,
      projectModelEndpoints,
      workflowStepFunctions,
      workflowEvents,
      customFunctions,
      templateWorkflowStepFunctions,
      templateWorkflowFunctions
    ]) => {
      const uiStepFunction = action.payload.uiStepFunction;

      return this.dataTransformationService.getPostDataTransformationSourceAndTargetSchemas(
        uiStepFunction,
        projectModelEndpoints,
        workflowStepFunctions,
        workflowEvents,
        customFunctions,
        templateWorkflowStepFunctions,
        templateWorkflowFunctions
      ).pipe(
        mergeMap((sourceAndTargetSchema) => [
          new UIWorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaDataSuccessful({
            postDataTransformationId: uiStepFunction.postDataTransformationId,
            postSourceJsonSchema: sourceAndTargetSchema.sourceSchema,
            postTargetJsonSchema: sourceAndTargetSchema.targetSchema
          })
        ])
      );
    }), catchError((error) => ([
      new UIWorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaDataFailure({ error, key: 'GetPostDTSourceAndTargetSchemaData', timestamp: Date.now() })
    ]))
  ));

  /**
 * end get post dt data process after getting source schema, target schema, and dt id
 *
 * @memberof UIWorkflowStepFunctionEffects
 */

  getPostDTSourceAndTargetSchemaDataSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<UIWorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaDataSuccessful>(
      UIWorkflowStepFunctionActions.ActionTypes.GetPostDTSourceAndTargetSchemaDataSuccessful
    ),
    mergeMap((action) => [
      new UIWorkflowStepFunctionActions.GetPostDataTransformationDataSuccessful({
        postDataTransformationId: action.payload.postDataTransformationId,
        sourceJsonSchema: action.payload.postSourceJsonSchema,
        targetJsonSchema: action.payload.postTargetJsonSchema
      })
    ]), catchError((error) => ([
      new UIWorkflowStepFunctionActions.ErrorAction({ error, key: 'GetPostDataTransformationDataFailure', timestamp: Date.now() })
    ]))
  ));


  getTemplateUIStepFunctions$ = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.SetActiveProject>(
      ActiveProjectActions.ActionTypes.SetActiveProject),

    mergeMap((action) => {
      const params = {
        filter: {
          where: { uiDataStoreId: UIDataStoreIdForTemplateWorkflows.filteredId },
          include: GetUIStepFunctionWithDetailsFilter
        }
      };
      return this.UIWorkflowStepFunctionApi.findServices(params).pipe(
        mergeMap((uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[]) => {
          {
            return [
              new UIWorkflowStepFunctionActions.GetTemplateUIStepFunctionsSuccessful({
                uiWorkflowStepFunctions: uiWorkflowStepFunctions
              })
            ];
          }
        }));
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'UI_WORKFLOW_TEMPLATE_COMPONENT.COULDNT_LOAD_TEMPLATE_UI_STEP_FUNCTIONS'
      );
      return [
        new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'TemplateUIStepFunctions', timestamp: Date.now() })
      ];
    })
  ));
};
