<rappider-spin [spinning]="loading">
  <section>
    <ng-container *ngIf="value">
      <ng-container *ngFor="let item of value; let i = index">
        <section class="preview-area">
          <div class="event-preview">
            {{getPublishedEventName(item.publishedEventId)}}
            <rappider-button *ngIf="item.condition"
                             [type]="previewButtonConfig.type"
                             [icon]="previewButtonConfig.icon"
                             nz-tooltip
                             [nzTooltipTitle]="conditionPreview"
                             [disabled]="disabled"></rappider-button>
          </div>
          <div class="edit-area">
            <rappider-button [text]="'SHARED.EDIT' | translate"
                             [type]="editPublishedEventButton.type"
                             [icon]="editPublishedEventButton.icon"
                             (click)="onClickEditPublishedEvent(item,i)"
                             [disabled]="disabled"></rappider-button>
            &nbsp;
            <rappider-button [text]="'SHARED.DELETE' | translate"
                             [type]="deletePublishedEventButton.type"
                             [icon]="deletePublishedEventButton.icon"
                             (click)="onDeletePublishedEvent(i)"
                             [disabled]="disabled"></rappider-button>
          </div>
          <ng-template #conditionPreview>
            <span [innerHTML]="getConditionPreview(item.condition)"></span>
          </ng-template>
        </section>
      </ng-container>
    </ng-container>
    <!-- FIXME: Add this back once we support multiple selection again -->
    <rappider-button [text]="addPublishedEventButton.text"
                     [type]="addPublishedEventButton.type"
                     [icon]="addPublishedEventButton.icon"
                     [disabled]="disabled"
                     (click)="onAddPublishedEvent()"></rappider-button>
  </section>
</rappider-spin>

<!-- Add Area -->
<rappider-modal [visible]="isAddPublishedEventVisible"
                [title]="'PROJECT_MODULE.UI_STEP_FUNCTION_IF_CONDITION_COMPONENT.ADD_PUBLISHED_EVENT' |translate"
                [fullScreen]="true"
                (cancelClick)="closeAddPublishedEventModal()"
                (okClick)="addPublishedEvent()">
  <div class="control-body">
    <rappider-heading type="h6"
                      [content]="'PROJECT_MODULE.UI_STEP_FUNCTION_IF_CONDITION_COMPONENT.PUBLISHED_EVENT' | translate">
    </rappider-heading>
    <rappider-select [options]="uiDataEventOptions"
                     [settings]="selectSettings"
                     [(ngModel)]="newPublishedEvent.publishedEventId"></rappider-select>
  </div>
    <nz-collapse nzGhost>
      <nz-collapse-panel nzHeader="Advanced Settings (Optional)"
                         nzActive="false">
  <div class="control-body">
    <rappider-heading type="h6"
                      [content]="'Delay as milliseconds' | translate">
    </rappider-heading>
    <rappider-textbox [(ngModel)]="newPublishedEvent.repeatedlyRunDelay"></rappider-textbox>
  </div>
  <div class="control-body">
    <rappider-heading type="h6"
                      [content]="'PROJECT_MODULE.UI_STEP_FUNCTION_IF_CONDITION_COMPONENT.CONDITION' | translate">
    </rappider-heading>

    <div class="condition">
      <rappider-filter-find-row-element [(ngModel)]="newPublishedEvent.condition"
                                        [visibility]="isAddPublishedEventVisible || isEditPublishedEventVisible"
                                        [functions]="functions"
                                        [tree]="tree"
                                        [jsonSchema]="jsonSchema"></rappider-filter-find-row-element>
    </div>
  </div>
      </nz-collapse-panel>
    </nz-collapse>

</rappider-modal>

<!-- Edit Area -->
<rappider-modal *ngIf="isEditPublishedEventVisible"
                [visible]="isEditPublishedEventVisible"
                [title]="'PROJECT_MODULE.UI_STEP_FUNCTION_IF_CONDITION_COMPONENT.EDIT_PUBLISHED_EVENT'| translate"
                [fullScreen]="true"
                (cancelClick)="closeEditPublishedEventModal()"
                (okClick)="onEditPublishedEvent()">
  <div class="control-body">
    <rappider-heading type="h6"
                      [content]="'PROJECT_MODULE.UI_STEP_FUNCTION_IF_CONDITION_COMPONENT.PUBLISHED_EVENT' | translate">
    </rappider-heading>
    <rappider-select [options]="uiDataEventOptions"
                     [(ngModel)]="editedPublishedEvent.publishedEventId"></rappider-select>
  </div>
  <div class="control-body">
    <rappider-heading type="h6"
                      [content]="'Delay as milliseconds' | translate">
    </rappider-heading>
    <rappider-textbox [(ngModel)]="editedPublishedEvent.repeatedlyRunDelay"></rappider-textbox>
  </div>
  <div class="control-body">
    <rappider-heading type="h6"
                      [content]="'PROJECT_MODULE.UI_STEP_FUNCTION_IF_CONDITION_COMPONENT.CONDITION' | translate">
    </rappider-heading>

    <div class="condition">
      <rappider-filter-find-row-element [(ngModel)]="editedPublishedEvent.condition"
                                        [functions]="functions"
                                        [visibility]="isAddPublishedEventVisible || isEditPublishedEventVisible"
                                        [tree]="tree"
                                        [jsonSchema]="jsonSchema"></rappider-filter-find-row-element>
    </div>
  </div>
</rappider-modal>
