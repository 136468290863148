import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType
} from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { POP_CONFIRM_DELETE_ACTION } from '../pop-confirm-button/pop-confirm-button-config';

export const PAGE_LAYOUT_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'title',
  orderable: true,
  columns: [
    {
      title: 'SHARED.ID',
      fieldName: 'id',
      visible: false,
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/{{id}}`,
      sortable: true
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.DEFAULT',
      fieldName: 'isDefault',
      type: CrudViewColumnType.Boolean
    }
  ],
  listActions: [
    {
      text: 'PAGES_MODULE.PAGE_LAYOUT_LIST_COMPONENT.ADD_PAGE_LAYOUT',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_CREATE_PATH}`
    }
  ],
  itemActions: [
    {
      text: 'SHARED.SET_AS_DEFAULT',
      behavior: ActionBehavior.Emit,
      name: 'SET_AS_DEFAULT',
      icon: { name: 'far fa-edit' },
    },
    {
      text: 'SHARED.EDIT',
      behavior: ActionBehavior.Route,
      name: 'EDIT_PAGE',
      icon: { name: 'far fa-edit' },
      redirectUrl: `${PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_EDIT_PATH}/{{id}}`
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ]
};
