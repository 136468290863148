/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { Tenant } from '@rappider/rappider-sdk';

export enum ActionTypes {
  LoadModule = '[Tenant] LoadModule',
  LoadModuleSuccess = '[Tenant] LoadModuleSuccess',
  LoadModuleFailure = '[Tenant] LoadModuleFailure',
  CreateTenant = '[Tenant] CreateTenant',
  UpdateTenant = '[Tenant] UpdateTenant',
  UpdateTenantSuccessful = '[Tenant] UpdateTenantSuccessful',
  UpdateTenantFailure = '[Tenant] UpdateTenantFailure',
  DeleteTenant = '[Tenant] DeleteTenant',
  EnableLoading = '[Tenant] EnableLoading',
  DisableLoading = '[Tenant] DisableLoading',
  TenantError = '[Tenant] TenantError',
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class LoadModuleSuccess implements Action {
  readonly type = ActionTypes.LoadModuleSuccess;
  constructor(public payload: { data?: any }) { }
}

export class LoadModuleFailure implements Action {
  readonly type = ActionTypes.LoadModuleFailure;
  constructor(public payload: { error: any }) { }
}

export class CreateTenant implements Action {
  readonly type = ActionTypes.CreateTenant;
  constructor(public payload: { tenant: Tenant }) { }
}

export class UpdateTenant implements Action {
  readonly type = ActionTypes.UpdateTenant;
  constructor(public payload: {
    tenant: Tenant;
    tenantId: string;
    navigateToProjectList?: boolean;
  }) { }
}

export class UpdateTenantSuccessful implements Action {
  readonly type = ActionTypes.UpdateTenantSuccessful;
  constructor(public payload: {
    tenant: Tenant;
    tenantId: string;
    navigateToProjectList?: boolean;
  }) { }
}


export class UpdateTenantFailure implements Action {
  readonly type = ActionTypes.UpdateTenantFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class DeleteTenant implements Action {
  readonly type = ActionTypes.DeleteTenant;
  constructor(public payload: { tenantId: string }) { }
}

export class EnableLoading implements Action {
  readonly type = ActionTypes.EnableLoading;
}

export class DisableLoading implements Action {
  readonly type = ActionTypes.DisableLoading;
}

export class TenantError implements Action {
  readonly type = ActionTypes.TenantError;
  constructor(public payload: { errorOn: string; error: any }) { }
}

export type Actions =
  LoadModule
  | LoadModuleSuccess
  | LoadModuleFailure
  | CreateTenant
  | UpdateTenant
  | UpdateTenantSuccessful
  | UpdateTenantFailure
  | DeleteTenant
  | EnableLoading
  | DisableLoading
  | TenantError
  ;
