import { CrudTableViewConfig, CrudViewColumnType } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

export const ADMIN_DASHBOARD_CONFIG = [
  {
    /* All Projects */
    icon: {
      type: 'FONT_AWESOME',
      name: 'fa-solid fa-laptop-file'
    },
    title: 'Projects',
    path: PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECTS
  },
  {
    /* Authorization */
    icon: {
      type: 'FONT_AWESOME',
      name: 'fa-solid fa-users'
    },
    title: 'Authorization',
    description: 'Crud operations for Roles and Permissions',
    subFeatures: [
      {
        title: 'Permission Management',
        path: PATH_DEFINITIONS.ADMIN_DASHBOARD.PERMISSIONS_CRUD
      },
      {
        title: 'Role Management',
        path: PATH_DEFINITIONS.ADMIN_DASHBOARD.ROLES_CRUD
      },
      {
        title: 'Person Management',
        path: PATH_DEFINITIONS.ADMIN_DASHBOARD.PERSON_MANAGEMENT
      },
      {
        title: 'Jira Dashboard',
        path: 'jira'
      }
    ]
  }
];
