import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

/* Admin Comment State */
import * as fromAdminComment from './admin-comment/admin-comment.reducer';
import { AdminCommentEffects } from './admin-comment/admin-comment.effects';

/* Admin Projects State */
import * as fromAdminProjects from './admin-projects/admin-projects.reducer';
import { AdminProjectsEffects } from './admin-projects/admin-projects.effects';

/* Person State */
import * as fromPersonReducer from 'libs/admin-dashboard/src/lib/state/person-state/person.reducer';
import { PersonEffects } from 'libs/admin-dashboard/src/lib/state/person-state/person.effects';

/* Role State */
import * as fromAdminPageReducer from 'libs/admin-dashboard/src/lib/state/admin-page-state/admin-page.reducer';
import { AdminPageEffects } from 'libs/admin-dashboard/src/lib/state/admin-page-state/admin-page.effects';

/* Role Crud State */
import * as fromRoleCrudReducer from 'libs/admin-dashboard/src/lib/state/role-crud-state/role-crud.reducer';
import { RoleCrudEffects } from 'libs/admin-dashboard/src/lib/state/role-crud-state/role-crud.effects';

/* Permission Crud State */
import * as fromPermissionReducer from 'libs/admin-dashboard/src/lib/state/permission-state/permission.reducer';
import { PermissionEffects } from 'libs/admin-dashboard/src/lib/state/permission-state/permission.effects';

/* Jira Dashboard State */
import * as fromAdminJiraDashboardReducer from 'libs/admin-dashboard/src/lib/state/jira-dashboard-state/jira-dashboard.reducer';
import { AdminJiraDashboardEffects } from './jira-dashboard-state/jira-dashboard.effects';

@NgModule({
  declarations: [],
  imports: [
    /* Admin Comment State */
    StoreModule.forFeature(
      fromAdminComment.featureKey,
      fromAdminComment.reducer,
      { initialState: fromAdminComment.initialState }
    ),
    EffectsModule.forFeature([AdminCommentEffects]),

    /* Admin Projects State */
    StoreModule.forFeature(
      fromAdminProjects.featureKey,
      fromAdminProjects.reducer,
      { initialState: fromAdminProjects.initialState }
    ),
    EffectsModule.forFeature([AdminProjectsEffects]),

    /* Person state */
    StoreModule.forFeature(
      fromPersonReducer.featureKey,
      fromPersonReducer.reducer,
      { initialState: fromPersonReducer.initialState }
    ),
    EffectsModule.forFeature([PersonEffects]),

    /* Admin Page state */
    StoreModule.forFeature(
      fromAdminPageReducer.featureKey,
      fromAdminPageReducer.reducer,
      { initialState: fromAdminPageReducer.initialState }
    ),
    EffectsModule.forFeature([AdminPageEffects]),

    /* Role Crud state */
    StoreModule.forFeature(
      fromRoleCrudReducer.featureKey,
      fromRoleCrudReducer.reducer,
      { initialState: fromRoleCrudReducer.initialState }
    ),
    EffectsModule.forFeature([RoleCrudEffects]),

    /* Permission Crud state */
    StoreModule.forFeature(
      fromPermissionReducer.featureKey,
      fromPermissionReducer.reducer,
      { initialState: fromPermissionReducer.initialState }
    ),
    EffectsModule.forFeature([PermissionEffects]),

    /* Admin Jira Dashboard state */
    StoreModule.forFeature(
      fromAdminJiraDashboardReducer.featureKey,
      fromAdminJiraDashboardReducer.reducer,
      { initialState: fromAdminJiraDashboardReducer.initialState }
    ),
    EffectsModule.forFeature([AdminJiraDashboardEffects])
  ]
})
export class AdminDashboardStateModule { }
