import { CrudTableViewConfig, CrudViewColumnType, HeadingType } from '@rappider/rappider-components/utils';

export const JIRA_DASHBOARD_ASSIGNEES_POINTS_CONFIG: CrudTableViewConfig = {
  showPagination: true,
  title: {
    type: HeadingType.H3,
    content: "STORY POINTS"
  },
  defaultSearchField: 'name',
  columns: [
    {
      title: 'Assignee',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Total Points',
      fieldName: 'totalStoryPoints',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'Closed',
      fieldName: 'statuses.Closed.storyPoints',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Needs Groom',
      fieldName: 'statuses.Needs Groom.storyPoints',
      type: CrudViewColumnType.Text
    },
    {
      title: 'To Do',
      fieldName: 'statuses.To Do.storyPoints',
      type: CrudViewColumnType.Text
    },
    {
      title: 'In Progress',
      fieldName: 'statuses.In Progress.storyPoints',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Code Review',
      fieldName: 'statuses.Code Review.storyPoints',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Waiting For Deploy',
      fieldName: 'statuses.Waiting For Deploy.storyPoints',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Ready To Test',
      fieldName: 'statuses.Ready To Test.storyPoints',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Done',
      fieldName: 'statuses.Done.storyPoints',
      type: CrudViewColumnType.Text
    }
  ]
};

export const JIRA_DASHBOARD_ASSIGNEES_TASKS_CONFIG: CrudTableViewConfig = {
  showPagination: true,
  title: {
    type: HeadingType.H3,
    content: "TASK COUNTS"
  },
  defaultSearchField: 'name',
  columns: [
    {
      title: 'Assignee',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Total Tasks',
      fieldName: 'totalTasks',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'Closed',
      fieldName: 'statuses.Closed.taskCount',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Needs Groom',
      fieldName: 'statuses.Needs Groom.taskCount',
      type: CrudViewColumnType.Text
    },
    {
      title: 'To Do',
      fieldName: 'statuses.To Do.taskCount',
      type: CrudViewColumnType.Text
    },
    {
      title: 'In Progress',
      fieldName: 'statuses.In Progress.taskCount',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Code Review',
      fieldName: 'statuses.Code Review.taskCount',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Waiting For Deploy',
      fieldName: 'statuses.Waiting For Deploy.taskCount',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Ready To Test',
      fieldName: 'statuses.Ready To Test.taskCount',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Done',
      fieldName: 'statuses.Done.taskCount',
      type: CrudViewColumnType.Text
    }
  ]
};
