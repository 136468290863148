import { IconType } from '@rappider/rappider-components/utils';

export const ThemeListButtonConfig = {
  text: 'Go to Theme',
  icon: {
    name: 'fa-solid fa-arrow-up-right-from-square',
    type: IconType.FontAwesome
  },
  iconPlacement: 'Left',
};
