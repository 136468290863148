<ng-container *ngIf="value">
  <ng-container *ngTemplateOutlet="logicTemplate; context:{ logicValue: value }"></ng-container>
</ng-container>

<ng-template #filterFindOperation
             let-operations="operations">
  <div *ngFor="let operation of operations; let i = index; trackBy: trackItems"
       class="operation"
       joyrideStep="filterLogicThirdStep"
       [title]="'DTFilterFindConditionItemsAndFunctionTitle' | translate"
       (done)="onDone()"
       [text]="'DTFilterFindConditionItemsAndFunctionContent' | translate">
    <ng-container *ngIf="operation.logic === undefined">
      <rappider-filter-find-row *ngIf="operation && tree"
                                #filterFindRow
                                [tree]="tree"
                                [visibility]="visibility"
                                [ngModel]="operation"
                                [functions]="functions"
                                [logicItemAndFunctionsFlag]="logicItemAndFunctionsFlag"
                                (showStepsFlag)="onShowStepsFlag($event)"
                                (ngModelChange)="onOperationChange(operation, $event)"
                                (deleteButtonClick)="onOperationDelete(operations, i)">
      </rappider-filter-find-row>
    </ng-container>
    <ng-container *ngIf="operation.logic !== undefined">
      <ng-container
                    *ngTemplateOutlet="logicTemplate; context:{ logicValue: operation, operations:operations, index: i}">
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #logicTemplate
             let-logicValue="logicValue"
             let-operations="operations"
             let-index="index">
  <section class="logic">
    <section class="control-buttons">
      <div class="filter-button"
           joyrideStep="filterLogicFirstStep"
           [title]="'DTFilterFindOperatorTitle' | translate"
           [text]="'DTFilterFindOperatorContent' | translate">
        <rappider-radio-group class="and-or-buttons"
                              [(ngModel)]="logicValue.logic"
                              [options]="radioGroupConfig"
                              (ngModelChange)="onValueChange()"></rappider-radio-group>
        <rappider-button [text]="addFilterButton.text"
                         [icon]="addFilterButton.icon"
                         (click)="onAddFilterClick(logicValue)"></rappider-button>
        <rappider-button *ngIf="index"
                         [icon]="deleteButton.icon"
                         (click)="onLogicDelete(operations,index)"></rappider-button>
      </div>
      <div class="logic-button"
           joyrideStep="filterLogicSecondStep"
           [title]="'DTFilterFindAddLogicTitle' | translate"
           [text]="'DTFilterFindAddLogicContent' | translate">
        <rappider-button [text]="addLogicButton.text"
                         [icon]="addLogicButton.icon"
                         (click)="onAddLogicClick(logicValue)"></rappider-button>
      </div>
    </section>
    <ng-container *ngIf="logicValue.operations">
      <ng-container *ngTemplateOutlet="filterFindOperation; context:{ operations: logicValue.operations }">
      </ng-container>
    </ng-container>
  </section>
</ng-template>
