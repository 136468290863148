import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GetJiraDashboard } from '../../state/jira-dashboard-state/jira-dashboard.actions';
import { JIRA_DASHBOARD_ASSIGNEES_POINTS_CONFIG, JIRA_DASHBOARD_ASSIGNEES_TASKS_CONFIG } from './config/jira-dashboard-config';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'jira-dashboard',
  templateUrl: './jira-dashboard.component.html',
  styleUrls: ['./jira-dashboard.component.scss']
})
export class JiraDashboardComponent implements OnInit, OnDestroy {

  JIRA_DASHBOARD_ASSIGNEES_POINTS_CONFIG = JIRA_DASHBOARD_ASSIGNEES_POINTS_CONFIG;
  JIRA_DASHBOARD_ASSIGNEES_TASKS_CONFIG = JIRA_DASHBOARD_ASSIGNEES_TASKS_CONFIG;

  /* subscriptions */
  subscriptions: Subscription[] = [];
  assigneesDashboardData;
  jiraDashboardLoading: boolean;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(GetJiraDashboard());
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToJiraDashboard(),
      this.subscribeToJiraDashboardLoading()
    ];
  }

  subscribeToJiraDashboardLoading() {
    return this.store.select(state => state.adminJiraDasboard.isLoading).subscribe((isLoading: boolean) => {
      this.jiraDashboardLoading = isLoading;
    });
  }

  subscribeToJiraDashboard() {
    return this.store.select(state => state.adminJiraDasboard.data).subscribe((assigneesDashboardData) => {

      if (assigneesDashboardData) {
        this.assigneesDashboardData = this.replaceZeroWithDashAndAppend(cloneDeep(assigneesDashboardData));
        this.JIRA_DASHBOARD_ASSIGNEES_POINTS_CONFIG.title.content = `${'STORY POINTS ' + assigneesDashboardData.sprintName}`;
        this.JIRA_DASHBOARD_ASSIGNEES_TASKS_CONFIG.title.content = `${'TASK COUNTS ' + assigneesDashboardData.sprintName}`;

        const newAssignee = {
          name: "Total",
          statuses: {
            "To Do": { taskCount: 0, storyPoints: 0 },
            "In Progress": { taskCount: 0, storyPoints: 0 },
            "Code Review": { taskCount: 0, storyPoints: 0 },
            "Ready To Test": { taskCount: 0, storyPoints: 0 },
            "Waiting For Deploy": { taskCount: 0, storyPoints: 0 },
            "Done": { taskCount: 0, storyPoints: 0 },
            "Closed": { taskCount: 0, storyPoints: 0 },
            "Needs Groom": { taskCount: 0, storyPoints: 0 }
          },
          totalTasks: 0,
          totalStoryPoints: 0
        };

        assigneesDashboardData.assignees.forEach(assignee => {
          Object.keys(assignee.statuses).forEach(status => {
            newAssignee.statuses[status].taskCount += assignee.statuses[status].taskCount;
            newAssignee.statuses[status].storyPoints += assignee.statuses[status].storyPoints;
          });
          newAssignee.totalTasks += assignee.totalTasks;
          newAssignee.totalStoryPoints += assignee.totalStoryPoints;
        });

        this.assigneesDashboardData.assignees.push(newAssignee);
        this.assigneesDashboardData = this.replaceZeroWithDashAndAppend(cloneDeep(this.assigneesDashboardData));
      }
    });
  }

  replaceZeroWithDashAndAppend(data: any): any {
    const replace = (obj: any) => {
      for (const key in obj) {
        if (obj[key] === 0) {
          obj[key] = '-';
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          replace(obj[key]);
        } else if (typeof obj[key] === 'number') {
          if (key === 'storyPoints' || key === 'totalStoryPoints') {
            obj[key] = `${obj[key]} Points`;
          } else if (key === 'taskCount' || key === 'totalTasks') {
            obj[key] = `${obj[key]} Tasks`;
          }
        }
      }
    };
    replace(data);
    return data;
  }

}
