import { ButtonSize } from "@rappider/rappider-components/utils";
import { IconType } from "libs/components/src/lib/utils/icon";

export const homePricingConfigApp = {
  limitedTimeOfferIcon: {
    name: 'fa-light fa-clock',
    color: '#ffffff',
    type: IconType.FontAwesome,
    size: '1rem'
  },
  limitedTimeOfferLabel: {
    content: 'Limited time offer: Get 2 Months Free MVP Development at a Fixed Price Per Month',
    colorSettings: {
      backgroundColor: 'var(--info-hover-color)',
      color: '#ffffff'
    },
    typography: {
      fontSize: '0.8rem'
    }
  },
  pricingCloseButtonIcon: {
    type: 'FONT_AWESOME',
    name: 'fa-solid fa-xmark',
    color:'#ffffff',
    size: 'var(--margin-4x)',
    customColorSettings: {
      backgroundColor: '#5682d4'
    },
    borderSettings:{
      borderColor:'#5682d4'
    }
  }
}
