<rappider-list-grid [config]="JIRA_DASHBOARD_ASSIGNEES_POINTS_CONFIG"
[data]="assigneesDashboardData?.assignees"
[loading]="jiraDashboardLoading"></rappider-list-grid>

<rappider-divider></rappider-divider>

<rappider-list-grid [config]="JIRA_DASHBOARD_ASSIGNEES_TASKS_CONFIG"
[data]="assigneesDashboardData?.assignees"
[loading]="jiraDashboardLoading"></rappider-list-grid>

