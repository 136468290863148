import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
  HorizontalPosition
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const DATA_SCHEMA_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_ELEMENT_CREATE}/{{id}}`
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    },
    {
      title: 'Category',
      fieldName: 'category',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.DATA_SCHEMA_CREATE_COMPONENT.ADD_DATA_SCHEMA',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_CREATE
    },
    {
      text: 'SHARED.REFRESH',
      name: 'SHARED.REFRESH',
      icon: { name: 'fas fa-sync' },
      behavior: ActionBehavior.Emit,
      horizontalPosition: HorizontalPosition.Right
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'SHARED.EDIT',
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_EDIT}/{{id}}`
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (action.name === 'SHARED.EDIT' && item.isUpdatable === false) {
      return false;
    }
    if (action.name === POP_CONFIRM_DELETE_ACTION.name && item.isDeletable === false) {
      return false;
    }
    return true;
  },
};
