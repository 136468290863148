/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { NewUiWorkflowStepFunction, UiWorkflowStepFunctionPublishedEventOnSuccess, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetUIWorkflowStepFunctions = '[UIStepFunction] GetUIWorkflowStepFunctions',
  GetUIWorkflowStepFunctionById = '[UIStepFunction] GetUIWorkflowStepFunctionById',

  CreateUIWorkflowStepFunction = '[UIStepFunction] CreateUIWorkflowStepFunction',
  UpdateUIWorkflowStepFunction = '[UIStepFunction] UpdateUIWorkflowStepFunction',
  DeleteUIWorkflowStepFunction = '[UIStepFunction] DeleteUIWorkflowStepFunction',
  DeleteUIWorkflowStepFunctionSuccessful = '[UIStepFunction] DeleteUIWorkflowStepFunctionSuccessful',
  GetUIWorkflowStepFunctionsSuccessful = '[UIStepFunction] GetUIWorkflowStepFunctionsSuccessful',
  GetUIWorkflowStepFunctionsAccordingToId = '[UIStepFunction] GetUIWorkflowStepFunctionsAccordingToId',
  GetUIWorkflowStepFunctionsAccordingToIdFailure = '[UIStepFunction] GetUIWorkflowStepFunctionsAccordingToIdFailure',
  GetUIWorkflowStepFunctionsAccordingToUIDataStoreId = '[UIStepFunction] GetUIWorkflowStepFunctionsAccordingToUIDataStoreId',
  GetUIWorkflowStepFunctionsAccordingToUIDataStoreIdFailure = '[UIStepFunction] GetUIWorkflowStepFunctionsAccordingToUIDataStoreIdFailure',
  CreateUIWorkflowStepFunctionSuccessful = '[UIStepFunction] CreateUIWorkflowStepFunctionSuccessful',
  BulkCreateUIWorkflowStepFunctionSuccessful = '[UIStepFunction] BulkCreateUIWorkflowStepFunctionSuccessful',
  UpdateUIWorkflowStepFunctionSuccessful = '[UIStepFunction] UpdateUIWorkflowStepFunctionSuccessful',
  GetUpdatedUIWorkflowStepFunctionWithDetails = '[UIStepFunction] GetUpdatedUIWorkflowStepFunctionWithDetails',
  GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful = '[UIStepFunction] GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful',
  /* Pre Data Transformation */
  GetPreDataTransformationData = '[UIStepFunction] GetPreDataTransformationData',
  GetPreDataTransformationDataSuccessful = '[UIStepFunction] GetPreDataTransformationDataSuccessful',
  GetPreDataTransformationDataFailure = '[UIStepFunction] GetPreDataTransformationDataFailure',
  CreatePreDataTransformationData = '[UIStepFunction] CreatePreDataTransformationData',
  CreatePreDataTransformationDataSuccessful = '[UIStepFunction] CreatePreDataTransformationDataSuccessful',
  CreatePreDataTransformationDataFailure = '[UIStepFunction] CreatePreDataTransformationDataFailure',
  GetPreDTSourceAndTargetSchemaData = '[UIStepFunction] GetPreDTSourceAndTargetSchemaData',
  GetPreDTSourceAndTargetSchemaDataSuccessful = '[UIStepFunction] GetPreDTSourceAndTargetSchemaDataSuccessful',
  GetPreDTSourceAndTargetSchemaDataFailure = '[UIStepFunction] GetPreDTSourceAndTargetSchemaDataFailure',
  /* Post Data Transformation */
  GetPostDataTransformationData = '[UIStepFunction] GetPostDataTransformationData',
  GetPostDataTransformationDataSuccessful = '[UIStepFunction] GetPostDataTransformationDataSuccessful',
  GetPostDataTransformationDataFailure = '[UIStepFunction] GetPostDataTransformationDataFailure',
  CreatePostDataTransformationData = '[UIStepFunction] CreatePostDataTransformationData',
  CreatePostDataTransformationDataSuccessful = '[UIStepFunction] CreatePostDataTransformationDataSuccessful',
  CreatePostDataTransformationDataFailure = '[UIStepFunction] CreatePostDataTransformationDataFailure',
  GetPostDTSourceAndTargetSchemaData = '[UIStepFunction] GetPostDTSourceAndTargetSchemaData',
  GetPostDTSourceAndTargetSchemaDataSuccessful = '[UIStepFunction] GetPostDTSourceAndTargetSchemaDataSuccessful',
  GetPostDTSourceAndTargetSchemaDataFailure = '[UIStepFunction] GetPostDTSourceAndTargetSchemaDataFailure',

  ClearPreDataTransformationData = '[UIStepFunction] ClearPreDataTransformationData',
  ClearPostDataTransformationData = '[UIStepFunction] ClearPostDataTransformationData',
  GetTemplateUIStepFunctionsSuccessful = '[UIStepFunction] GetTemplateUIStepFunctionsSuccessful',
  GetTemplateUIStepFunctionsFailure = '[UIStepFunction] GetTemplateUIStepFunctionsFailure',
  TogglePreDataTransformationModalVisibility = '[UIStepFunction] TogglePreDataTransformationModalVisibility',
  TogglePostDataTransformationModalVisibility = '[UIStepFunction] TogglePostDataTransformationModalVisibility',

  GetUIWorkflowStepFunctionPublishedEventOnSuccess = '[UIStepFunction] GetUIWorkflowStepFunctionPublishedEventOnSuccess',
  GetUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[UIStepFunction] GetUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  GetUIWorkflowStepFunctionPublishedEventOnSuccessFailure = '[UIStepFunction] GetUIWorkflowStepFunctionPublishedEventOnSuccessFailure',

  UpdateUIWorkflowStepFunctionPublishedEventOnSuccess = '[UIStepFunction] UpdateUIWorkflowStepFunctionPublishedEventOnSuccess',
  UpdateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[UIStepFunction] UpdateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  UpdateUIWorkflowStepFunctionPublishedEventOnSuccessFailure = '[UIStepFunction] UpdateUIWorkflowStepFunctionPublishedEventOnSuccessFailure',

  CreateUIWorkflowStepFunctionPublishedEventOnSuccess = '[UIStepFunction] CreateUIWorkflowStepFunctionPublishedEventOnSuccess',
  CreateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[UIStepFunction] CreateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  CreateUIWorkflowStepFunctionPublishedEventOnSuccessFailure = '[UIStepFunction] CreateUIWorkflowStepFunctionPublishedEventOnSuccessFailure',

  DeleteUIWorkflowStepFunctionPublishedEventOnSuccess = '[UIStepFunction] DeleteUIWorkflowStepFunctionPublishedEventOnSuccess',
  DeleteUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[UIStepFunction] DeleteUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  DeleteUIWorkflowStepFunctionPublishedEventOnSuccessFailure = '[UIStepFunction] DeleteUIWorkflowStepFunctionPublishedEventOnSuccessFailure',

  ErrorAction = '[UIStepFunction] ErrorAction'
}

export class GetUIWorkflowStepFunctions implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctions;
}

export class GetUIWorkflowStepFunctionById implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionById;
  constructor(public payload: { uiWorkflowStepFunctionId: string }) { }
}

export class GetUIWorkflowStepFunctionsSuccessful implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionsSuccessful;
  constructor(public payload: { uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[] }) { }
}

export class GetUIWorkflowStepFunctionsAccordingToId implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionsAccordingToId;
  constructor(public payload: { uiWorkflowStepFunctionsIds: string[] }) { }
}

export class GetUIWorkflowStepFunctionsAccordingToIdFailure implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionsAccordingToIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetUIWorkflowStepFunctionsAccordingToUIDataStoreId implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionsAccordingToUIDataStoreId;
  constructor(public payload: { uiDataStoreId: string }) { }
}

export class GetUIWorkflowStepFunctionsAccordingToUIDataStoreIdFailure implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionsAccordingToUIDataStoreIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class CreateUIWorkflowStepFunction implements Action {
  readonly type = ActionTypes.CreateUIWorkflowStepFunction;
  constructor(public payload: { uiWorkflowStepFunction: NewUiWorkflowStepFunction; navigateAfterCreate: boolean }) { }
}

export class UpdateUIWorkflowStepFunction implements Action {
  readonly type = ActionTypes.UpdateUIWorkflowStepFunction;
  constructor(public payload: {
    uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations;
    navigateAfterUpdate: boolean; uiDataStoreId: string;
  }) { }
}

export class UpdateUIWorkflowStepFunctionSuccessful implements Action {
  readonly type = ActionTypes.UpdateUIWorkflowStepFunctionSuccessful;
  constructor(public payload: { uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}

export class CreateUIWorkflowStepFunctionSuccessful implements Action {
  readonly type = ActionTypes.CreateUIWorkflowStepFunctionSuccessful;
  constructor(public payload: { uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}

export class BulkCreateUIWorkflowStepFunctionSuccessful implements Action {
  readonly type = ActionTypes.BulkCreateUIWorkflowStepFunctionSuccessful;
  constructor(public payload: { uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[] }) { }
}

export class DeleteUIWorkflowStepFunction implements Action {
  readonly type = ActionTypes.DeleteUIWorkflowStepFunction;
  constructor(public payload: { uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}

export class DeleteUIWorkflowStepFunctionSuccessful implements Action {
  readonly type = ActionTypes.DeleteUIWorkflowStepFunctionSuccessful;
  constructor(public payload: { id: string }) { }
}

export class GetUpdatedUIWorkflowStepFunctionWithDetails implements Action {
  readonly type = ActionTypes.GetUpdatedUIWorkflowStepFunctionWithDetails;
  constructor(public payload: { uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}

export class GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful implements Action {
  readonly type = ActionTypes.GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful;
  constructor(public payload: { uiWorkflowStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}

/* Pre Data Transformation */
export class GetPreDataTransformationData implements Action {
  readonly type = ActionTypes.GetPreDataTransformationData;
  constructor(public payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}

export class GetPreDataTransformationDataSuccessful implements Action {
  readonly type = ActionTypes.GetPreDataTransformationDataSuccessful;
  constructor(public payload: { preDataTransformationId: string; sourceJsonSchema: any; targetJsonSchema: any }) { }
}

export class GetPreDataTransformationDataFailure implements Action {
  readonly type = ActionTypes.GetPreDataTransformationDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class CreatePreDataTransformationData implements Action {
  readonly type = ActionTypes.CreatePreDataTransformationData;
  constructor(public payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}
export class CreatePreDataTransformationDataSuccessful implements Action {
  readonly type = ActionTypes.CreatePreDataTransformationDataSuccessful;
  constructor(public payload: { preDataTransformationId: string; uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}
export class CreatePreDataTransformationDataFailure implements Action {
  readonly type = ActionTypes.CreatePreDataTransformationDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetPreDTSourceAndTargetSchemaData implements Action {
  readonly type = ActionTypes.GetPreDTSourceAndTargetSchemaData;
  constructor(public payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}
export class GetPreDTSourceAndTargetSchemaDataSuccessful implements Action {
  readonly type = ActionTypes.GetPreDTSourceAndTargetSchemaDataSuccessful;
  constructor(public payload: { preDataTransformationId: string; preSourceJsonSchema: any; preTargetJsonSchema: any }) { }
}
export class GetPreDTSourceAndTargetSchemaDataFailure implements Action {
  readonly type = ActionTypes.GetPreDTSourceAndTargetSchemaDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}


/* Post Data Transformation */
export class GetPostDataTransformationData implements Action {
  readonly type = ActionTypes.GetPostDataTransformationData;
  constructor(public payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}

export class GetPostDataTransformationDataSuccessful implements Action {
  readonly type = ActionTypes.GetPostDataTransformationDataSuccessful;
  constructor(public payload: { postDataTransformationId: string; sourceJsonSchema: any; targetJsonSchema: any }) { }
}

export class GetPostDataTransformationDataFailure implements Action {
  readonly type = ActionTypes.GetPostDataTransformationDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class CreatePostDataTransformationData implements Action {
  readonly type = ActionTypes.CreatePostDataTransformationData;
  constructor(public payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}
export class CreatePostDataTransformationDataSuccessful implements Action {
  readonly type = ActionTypes.CreatePostDataTransformationDataSuccessful;
  constructor(public payload: { postDataTransformationId: string; uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}
export class CreatePostDataTransformationDataFailure implements Action {
  readonly type = ActionTypes.CreatePostDataTransformationDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetPostDTSourceAndTargetSchemaData implements Action {
  readonly type = ActionTypes.GetPostDTSourceAndTargetSchemaData;
  constructor(public payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations }) { }
}
export class GetPostDTSourceAndTargetSchemaDataSuccessful implements Action {
  readonly type = ActionTypes.GetPostDTSourceAndTargetSchemaDataSuccessful;
  constructor(public payload: { postDataTransformationId: string; postSourceJsonSchema: any; postTargetJsonSchema: any }) { }
}
export class GetPostDTSourceAndTargetSchemaDataFailure implements Action {
  readonly type = ActionTypes.GetPostDTSourceAndTargetSchemaDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class ClearPreDataTransformationData implements Action {
  readonly type = ActionTypes.ClearPreDataTransformationData;
}

export class ClearPostDataTransformationData implements Action {
  readonly type = ActionTypes.ClearPostDataTransformationData;
}


export class GetTemplateUIStepFunctionsSuccessful implements Action {
  readonly type = ActionTypes.GetTemplateUIStepFunctionsSuccessful;
  constructor(public payload: { uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[] }) { }
}
export class GetTemplateUIStepFunctionsFailure implements Action {
  readonly type = ActionTypes.GetTemplateUIStepFunctionsFailure;
  constructor(public payload: { timestamp?: number; key?: string; error?: unknown }) { }
}
export class TogglePreDataTransformationModalVisibility implements Action {
  readonly type = ActionTypes.TogglePreDataTransformationModalVisibility;
}

export class TogglePostDataTransformationModalVisibility implements Action {
  readonly type = ActionTypes.TogglePostDataTransformationModalVisibility;
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetUIWorkflowStepFunctionPublishedEventOnSuccess implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionPublishedEventOnSuccess;
}

export class GetUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful;
  constructor(public payload: { uiWorkflowStepFunctionPublishedEventOnSuccess: UiWorkflowStepFunctionPublishedEventOnSuccess[] }) { }
}

export class GetUIWorkflowStepFunctionPublishedEventOnSuccessFailure implements Action {
  readonly type = ActionTypes.GetUIWorkflowStepFunctionPublishedEventOnSuccessFailure;
}

export class UpdateUIWorkflowStepFunctionPublishedEventOnSuccess implements Action {
  readonly type = ActionTypes.UpdateUIWorkflowStepFunctionPublishedEventOnSuccess;
}

export class UpdateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful implements Action {
  readonly type = ActionTypes.UpdateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful;
  constructor(public payload: { uiWorkflowStepFunctionPublishedEventOnSuccess: UiWorkflowStepFunctionPublishedEventOnSuccess }) { }
}

export class UpdateUIWorkflowStepFunctionPublishedEventOnSuccessFailure implements Action {
  readonly type = ActionTypes.UpdateUIWorkflowStepFunctionPublishedEventOnSuccessFailure;
}

export class CreateUIWorkflowStepFunctionPublishedEventOnSuccess implements Action {
  readonly type = ActionTypes.CreateUIWorkflowStepFunctionPublishedEventOnSuccess;
}

export class CreateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful implements Action {
  readonly type = ActionTypes.CreateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful;
  constructor(public payload: { uiWorkflowStepFunctionPublishedEventOnSuccess: UiWorkflowStepFunctionPublishedEventOnSuccess }) { }
}

export class CreateUIWorkflowStepFunctionPublishedEventOnSuccessFailure implements Action {
  readonly type = ActionTypes.CreateUIWorkflowStepFunctionPublishedEventOnSuccessFailure;
}

export class DeleteUIWorkflowStepFunctionPublishedEventOnSuccess implements Action {
  readonly type = ActionTypes.DeleteUIWorkflowStepFunctionPublishedEventOnSuccess;
}

export class DeleteUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful implements Action {
  readonly type = ActionTypes.DeleteUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful;
  constructor(public payload: { id: string }) { }
}

export class DeleteUIWorkflowStepFunctionPublishedEventOnSuccessFailure implements Action {
  readonly type = ActionTypes.DeleteUIWorkflowStepFunctionPublishedEventOnSuccessFailure;
}

export type Actions =
  GetUIWorkflowStepFunctions
  | GetUIWorkflowStepFunctionById
  | GetUIWorkflowStepFunctionsSuccessful
  | GetUIWorkflowStepFunctionsAccordingToId
  | GetUIWorkflowStepFunctionsAccordingToIdFailure
  | GetUIWorkflowStepFunctionsAccordingToUIDataStoreId
  | GetUIWorkflowStepFunctionsAccordingToUIDataStoreIdFailure
  | ErrorAction
  | CreateUIWorkflowStepFunction
  | UpdateUIWorkflowStepFunction
  | DeleteUIWorkflowStepFunction
  | CreateUIWorkflowStepFunctionSuccessful
  | BulkCreateUIWorkflowStepFunctionSuccessful
  | UpdateUIWorkflowStepFunctionSuccessful
  | DeleteUIWorkflowStepFunctionSuccessful
  | GetUpdatedUIWorkflowStepFunctionWithDetails
  | GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful
  /* Pre Data Transformation */
  | GetPreDataTransformationData
  | GetPreDataTransformationDataSuccessful
  | GetPreDataTransformationDataFailure
  | CreatePreDataTransformationData
  | CreatePreDataTransformationDataSuccessful
  | CreatePreDataTransformationDataFailure
  | GetPreDTSourceAndTargetSchemaData
  | GetPreDTSourceAndTargetSchemaDataSuccessful
  | GetPreDTSourceAndTargetSchemaDataFailure
  /* Post Data Transformation */
  | GetPostDataTransformationData
  | GetPostDataTransformationDataSuccessful
  | GetPostDataTransformationDataFailure
  | CreatePostDataTransformationData
  | CreatePostDataTransformationDataSuccessful
  | CreatePostDataTransformationDataFailure
  | GetPostDTSourceAndTargetSchemaData
  | GetPostDTSourceAndTargetSchemaDataSuccessful
  | GetPostDTSourceAndTargetSchemaDataFailure

  | ClearPreDataTransformationData
  | ClearPostDataTransformationData
  | GetTemplateUIStepFunctionsSuccessful
  | GetTemplateUIStepFunctionsFailure
  | TogglePreDataTransformationModalVisibility
  | TogglePostDataTransformationModalVisibility
  | GetUIWorkflowStepFunctionPublishedEventOnSuccess
  | GetUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful
  | GetUIWorkflowStepFunctionPublishedEventOnSuccessFailure
  | UpdateUIWorkflowStepFunctionPublishedEventOnSuccess
  | UpdateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful
  | UpdateUIWorkflowStepFunctionPublishedEventOnSuccessFailure
  | CreateUIWorkflowStepFunctionPublishedEventOnSuccess
  | CreateUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful
  | CreateUIWorkflowStepFunctionPublishedEventOnSuccessFailure
  | DeleteUIWorkflowStepFunctionPublishedEventOnSuccess
  | DeleteUIWorkflowStepFunctionPublishedEventOnSuccessSuccessful
  | DeleteUIWorkflowStepFunctionPublishedEventOnSuccessFailure
  ;
