import { KeyValue } from '@angular/common';
import { Component, forwardRef, Input, OnChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonComponentConfig, ButtonType, IconType, NgZorroIconTheme } from '@rappider/rappider-components/utils';
import { DataTransformationControllerService, UiWorkflowStepFunctionControllerService } from '@rappider/rappider-sdk';
import { SchemaTreeService } from 'libs/data-transformation/src/lib/services/schema-tree/schema-tree.service';
import { cloneDeep } from 'lodash';
import { NzTreeNode } from 'ng-zorro-antd/tree';

@Component({
  selector: 'rappider-ui-step-function-if-condition',
  templateUrl: './ui-step-function-if-condition.component.html',
  styleUrls: ['./ui-step-function-if-condition.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => UiStepFunctionIfConditionComponent),
      multi: true
    }
  ]
})
export class UiStepFunctionIfConditionComponent implements ControlValueAccessor, OnChanges, OnInit {

  @Input() subscribedEventId: string;
  @Input() subscribedFieldIds: string[];
  @Input() uiDataEvents: KeyValue<string, string>[];
  @Input() disabled? = false;

  @Output() publishedEventSubmit = new EventEmitter<void>();

  _value: any[];
  tree: NzTreeNode[];
  loading = false;
  jsonSchema: Record<string, unknown>;
  uiDataEventOptions: KeyValue<string, string>[];
  isAddPublishedEventVisible = false;
  isEditPublishedEventVisible = false;

  newPublishedEvent = {
    condition: null,
    publishedEventId: null,
    repeatedlyRunDelay: null
  };

  editedPublishedEvent: Record<string, unknown>;
  editedPublishedEventIndex: number;
  functions: Record<string, unknown>[];

  editPublishedEventButton: ButtonComponentConfig = {
    text: 'SHARED.EDIT',
    type: ButtonType.Default,
    icon: {
      name: 'far fa-edit '
    }
  };

  deletePublishedEventButton: ButtonComponentConfig = {
    text: 'SHARED.DELETE',
    type: ButtonType.Default,
    icon: {
      name: 'far fa-trash'
    }
  };

  previewButtonConfig: ButtonComponentConfig = {
    type: ButtonType.Link,
    icon: {
      type: IconType.NgZorro,
      name: 'control',
      theme: NgZorroIconTheme.Outline
    }
  };

  addPublishedEventButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.UI_STEP_FUNCTION_IF_CONDITION_COMPONENT.ADD_PUBLISHED_EVENT',
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };

  selectSettings = {
    searchable: true
  };

  constructor(
    private uiStepFunctionApi: UiWorkflowStepFunctionControllerService,
    private schemaTreeService: SchemaTreeService,
    private dataTransformationApi: DataTransformationControllerService
  ) { }

  ngOnInit(): void {
    this.getFunctions();
    this.getUIDataEventOptions();
  }

  ngOnChanges(): void {
    this.getTree();
    this.getUIDataEventOptions();
  }

  getUIDataEventOptions() {
    this.uiDataEventOptions = cloneDeep(this.uiDataEvents);
    this.filterEvents();
  }

  getTree() {
    this.loading = true;
    this.uiStepFunctionApi.calculateJsonSchema(
      {
        body: {
          subscribedFieldIds: this.subscribedFieldIds, subscribedEventId: this.subscribedEventId
        }
      }).subscribe(jsonSchema => {
        this.tree = this.schemaTreeService.buildTreeFromJSONSchema(jsonSchema);
        this.jsonSchema = jsonSchema;
        this.loading = false;
      }, (() => {
        this.tree = [];
        this.jsonSchema = undefined;
        this.loading = false;
      }));
  }

  get value() {
    return this._value;
  }

  set value(value: any[]) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any[]) {
    this._value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getFunctions() {
    this.loading = true;
    this.dataTransformationApi.getDataTransformationItemOperationFunctions().subscribe(functions => {
      this.functions = functions.filter;
      this.loading = false;
    });
  }

  onAddPublishedEvent() {
    this.openAddPublishedEventModal();
  }

  openAddPublishedEventModal() {
    this.isAddPublishedEventVisible = true;
  }

  closeAddPublishedEventModal() {
    this.newPublishedEvent = {
      condition: null,
      publishedEventId: null,
      repeatedlyRunDelay: null
    };
    this.isAddPublishedEventVisible = false;
  }

  openEditPublishedEventModal() {
    this.isEditPublishedEventVisible = true;
  }

  closeEditPublishedEventModal() {
    this.editedPublishedEvent = null;
    this.editedPublishedEventIndex = null;
    this.isEditPublishedEventVisible = false;
  }

  addPublishedEvent() {
    if (this.newPublishedEvent.publishedEventId) {
      if (this.value) {
        this.value.push(this.newPublishedEvent);
      } else {
        this.value = [cloneDeep(this.newPublishedEvent)];
      }
      this.filterEvents();
    }
    this.closeAddPublishedEventModal();
    this.publishedEventSubmit.emit();
  }

  getConditionPreview(item: any) {
    const conditionPreview = item.operations.map(operation => {
      if (!operation.logic) {
        // const isItemHasLogicalOperation = item.operations.some(operation => !!operation.logic);
        const operationPreviewName = `${operation.function}(${operation.sourceField})`;
        return operationPreviewName;
      } else {
        return `(<br/>${this.getConditionPreview(operation)}<br/>)`;
      }
    }).join(` ${item.logic}<br/>`);
    return conditionPreview;
  }

  getPublishedEventName(publishedEventId: string) {
    return this.uiDataEvents.find(dataEvent => dataEvent.value === publishedEventId)?.key;
  }

  onClickEditPublishedEvent(item: any, index: number) {
    this.editedPublishedEvent = item;
    this.editedPublishedEventIndex = index;
    this.filterEvents();
    this.openEditPublishedEventModal();
  }

  onDeletePublishedEvent(index: number) {
    this.value.splice(index, 1);
  }

  onEditPublishedEvent() {
    this.value[this.editedPublishedEventIndex] = cloneDeep(this.editedPublishedEvent);
    this.closeEditPublishedEventModal();
    this.publishedEventSubmit.emit();
    this.filterEvents();
  }

  /**
   * this function filters selected ui data events
   * in order to prevent duplication
   *
   * @memberof UiStepFunctionIfConditionComponent
   */
  filterEvents() {
    if (this.value?.length) {
      this.uiDataEventOptions = this.uiDataEvents.filter(
        uiDataEvent => !this.value.some(
          item => (item.publishedEventId === uiDataEvent.value)
            /** if editedPublishedEvent currently exists (means that user currently edits) then dont filter */
            && (this.editedPublishedEvent?.publishedEventId !== uiDataEvent.value)
        )
      );
    }
  }

}
