import { createAction, createReducer, on } from '@ngrx/store';
import { CommentWithRelations } from '@rappider/rappider-sdk';
import * as AdminJiraDashboardActions from './jira-dashboard.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'adminJiraDasboard';

/* state interface */
export interface State {
  data: any[] | null;
  isLoading: boolean
}

export const initialState: State = {
  data: null,
  isLoading: true
};

const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(AdminJiraDashboardActions.GetJiraDashboard, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminJiraDashboardActions.GetJiraDashboardSuccessful, (state, action) => ({
    ...state,
    data: action.data,
    isLoading: false
  })),
  on(AdminJiraDashboardActions.GetJiraDashboardFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),
  on(Logout, () => (initialState))
);
