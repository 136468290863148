import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const UI_DATA_STORE_FIELD_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_FIELD_EDIT}/{{id}}`
    },
    {
      title: 'PROJECT_MODULE.UI_DATA_STORE_FIELD_LIST_COMPONENT.INITIAL_VALUE',
      fieldName: 'initialValue',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.UI_DATA_STORE_FIELD_CREATE_COMPONENT.UI_DATA_STORE_FIELD_ADD',
      buttonType: ButtonType.Primary,
      behavior: ActionBehavior.Emit,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_CREATE}/:uiDataStoreId`
    }
  ],
  itemActions: [
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ]
};
