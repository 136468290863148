export const QUERY_PARAM_DEFINITIONS = {
  PROJECT: {
    UI_DATA_STORE_DETAIL: {
      FIELDS_TAB: { tab: 'fields' },
      EVENTS_TAB: { tab: 'events' },
      DATA_EVENT_UPDATE_FUNCTION_TAB: { tab: 'data-event-update-function' },
      UI_STEP_FUNCTIONS_TAB: { tab: 'ui-step-functions' }
    },
    DATA_FIELD_LIST_COMPONENT: {
      FIELDS_TAB: { tab: 'fields' },
      ENDPOINTS_TAB: { tab: 'endpoints' },
      RELATIONS_TAB: { tab: 'relations' }
    },
  }
};
