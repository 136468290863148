import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ThemeMode } from '@rappider/models';
import { Page, ProcessInfoWithRelations, Project, ProjectWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { PATH_DEFINITIONS, ProcessInfoIdentifier, ProcessInfoStatus } from '@rappider/shared/definitions';
import { UpdatePreferredThemeForActiveUser } from 'libs/authentication/src/lib/state/authentication.actions';
import { ContentEditorClipboardData } from 'libs/content-editor/src/lib/models/content-editor-clipboard-data.interface';
import { activeContentTreeItemSelector } from 'libs/content-editor/src/lib/selectors/active-content-tree-item.selector';
import { contentTreeWithRelations } from 'libs/content-editor/src/lib/selectors/content-tree-with-details.selector';
import { SetActiveContentTreeItem } from 'libs/content-editor/src/lib/state/content-editor.actions';
import { ContentTree, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import { GetActiveProject } from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import { TreeService } from 'libs/shared/src/lib/services/tree-service/tree.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() activeProject: Project;
  @Input() selectedTheme: ThemeMode;

  subscriptions: Subscription[];
  projects: ProjectWithRelations[];

  // #region Content Editor Variables

  /* page data for content editor */
  contentEditorActivePage: Page;
  /* Clipboard data for content editor */
  clipboardData: ContentEditorClipboardData;
  isClipboardDataVisible: boolean;
  /* node to root path for content editor content tree */
  activeNodeToRootPath;
  activeContentTreeItem;
  contentTree: ContentTree;
  ContentTreeItemType = ContentTreeItemType;

  // #endregion Content Editor Variables
  processInfoStatus: string;
  ProcessInfoStatus = ProcessInfoStatus;
  projectCreationStatus = false;
  projectDownloadStatus = false;
  projectGenerateStatus = false;
  constructor(
    private router: Router,
    private store: Store<any>,
    private treeService: TreeService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToRouter(),
      this.subscribeToActiveContentTreeItem(),
      this.subscribeToActiveContentTree(),
      this.subscribeToActivePageOnContentEditor(),
      this.subscribeToContentEditorClipboardData(),
      this.subscribeToProjects(),
      this.subscribeToProcessInfos(),
      this.subscribeToDownloadProcess(),
      this.subscribeToGenerateProcess()
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToRouter() {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isClipboardDataVisible = event.urlAfterRedirects.includes('content-editor');
      }
    });
  }

  subscribeToProjects() {
    return this.store.select(state => state.project.data).subscribe((projects: ProjectWithRelations[]) => {
      this.projects = projects;
    });
  }

  subscribeToProcessInfos() {
    return this.store.select(state => state.processInfo.data).subscribe((processInfos: ProcessInfoWithRelations[]) => {
      this.processInfoStatus = processInfos[0]?.status;
      if (processInfos[0]?.identifier === ProcessInfoIdentifier.ProjectCreate && processInfos[0]?.status === ProcessInfoStatus.Started) {
        this.projectCreationStatus = true;
      } else if (!processInfos?.length) {
        this.projectCreationStatus = false;
      }
      if (processInfos[0]?.identifier === ProcessInfoIdentifier.ProjectCreate && processInfos[0]?.status === ProcessInfoStatus.Completed && this.projectCreationStatus) {
        this.notificationService.createNotification(
          'success',
          'Success',
          'The project creation process has been successfully completed.'
        );
      }
    });
  }


  subscribeToDownloadProcess() {
    return this.store.select(state => state.projectSourceCode?.isProjectDownloadLoading).subscribe((isProjectDownloadLoading: boolean) => {
      this.projectDownloadStatus = isProjectDownloadLoading;
    });
  }

  subscribeToGenerateProcess() {
    return this.store.select(state => state.projectSourceCode?.isProjectGenerateCodeLoading).subscribe((isProjectGenerateCodeLoading: boolean) => {
      this.projectGenerateStatus = isProjectGenerateCodeLoading;
    });
  }

  subscribeToActiveContentTreeItem() {
    return this.store.select(<any>activeContentTreeItemSelector)
      .subscribe(activeContentTreeItem => {
        this.activeContentTreeItem = activeContentTreeItem;
        this.calculateActiveNodeToRootPath();
      });
  }

  subscribeToActiveContentTree() {
    return this.store.select(<any>contentTreeWithRelations)
      .subscribe(contentTree => {
        this.contentTree = contentTree;
      });
  }

  subscribeToActivePageOnContentEditor() {
    return this.store.select(state => state.contentEditor?.page)
      .subscribe(page => {
        this.contentEditorActivePage = page;
      });
  }

  subscribeToContentEditorClipboardData() {
    return this.store.select(state => state.contentEditor?.clipboard)
      .subscribe(clipboardData => {
        this.clipboardData = clipboardData;
      });
  }

  calculateActiveNodeToRootPath() {
    if (this.activeContentTreeItem) {
      /*
       * If the selected content is system generated,first find the path of the system generated component.
       * Then find the path again in the tree of the system generated component, and combine these two paths.
       */
      if (this.activeContentTreeItem.parentPageComponentContentId) {
        const parentPageComponent = this.treeService.findNodeInTree(this.contentTree[0], this.activeContentTreeItem.parentPageComponentContentId, 'id', ['children']);
        this.activeNodeToRootPath = this.treeService.findNodeToRootPath(this.contentTree[0], parentPageComponent.id, 'id', ['children'], true)?.reverse();
        this.activeNodeToRootPath.push(...<any>this.treeService.findNodeToRootPath(parentPageComponent.component.componentDefinition.contentTreeItem, this.activeContentTreeItem.id, 'id', ['children'], true)?.reverse());
      } else {
        this.activeNodeToRootPath = this.treeService.findNodeToRootPath(this.contentTree[0], this.activeContentTreeItem.id, 'id', ['children'], true)?.reverse();
      }
    } else {
      this.activeNodeToRootPath = [];
    }
  }

  navigateToProjectDetailPage() {
    this.router.navigate([`${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`]);
  }

  changeSelectedTheme() {
    // remove old class
    document.body.classList.remove(this.selectedTheme);
    this.selectedTheme = this.selectedTheme === ThemeMode.Dark ? ThemeMode.Light : ThemeMode.Dark;
    // add new class
    document.body.classList.add(this.selectedTheme);
    this.store.dispatch(new UpdatePreferredThemeForActiveUser({ preferredTheme: this.selectedTheme }));
  }

  setActiveContentTreeItem(contentTreeItem) {
    this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId: contentTreeItem.id }));
  }

  onProjectSelect(project) {
    this.store.dispatch(new GetActiveProject({ projectId: project.id, navigateToProjectDetail: true }));
  }
}
