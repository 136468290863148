import { DropdownMenuComponentConfig, DropdownMenuTriggerType, DropdownType, IconType, MenuActionBehavior } from '@rappider/rappider-components/utils';

export const ThemeListDropdownMenuConfig: DropdownMenuComponentConfig = {
  icon: {
    name: 'fa-solid fa-ellipsis',
    type: IconType.FontAwesome
  },
  label: 'More Settings',
  dropdownType: DropdownType.Button,
  triggerType: DropdownMenuTriggerType.Click,
  items: [
    {
      label: 'Set As Active',
      key: 'set-as-active-theme',
      icon: {
        name: 'check-circle',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    {
      label: 'SHARED.EDIT',
      key: 'edit-theme',
      icon: {
        name: 'edit',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    {
      label: 'SHARED.DELETE',
      key: 'delete-theme',
      icon: {
        name: 'delete',
        type: IconType.NgZorro
      },
      popconfirmTitle: 'Are you sure you want to delete this project theme?',
      popconfirmOkDanger: true,
      popconfirmOkText:'Delete',
      actionBehavior: MenuActionBehavior.Emit
    }
  ]
};
