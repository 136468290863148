import { ActionBehavior } from '@rappider/rappider-components/utils';
import { CrudTableViewConfig, CrudViewColumnType } from 'libs/components/src/lib/utils/list-grid';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const PROJECT_MEMBERS_CONFIG: CrudTableViewConfig = {
  columns: [
    {
      title: 'Id',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'First Name',
      fieldName: 'firstName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Last Name',
      fieldName: 'lastName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Roles',
      fieldName: 'roles',
      type: CrudViewColumnType.Text
    }
  ],
  itemActions: [
    {
      text: 'Remove User',
      name: 'REMOVE_USER',
      behavior: ActionBehavior.Emit,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
      popconfirmTitle: 'Are you sure to remove this person from the project?',
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (item.roles === 'Project Owner') {
      return false;
    } else {
      return true;
    }
  },
  defaultSearchField: 'firstName',
  pageSize: 15,
  showPagination: true
};
