import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderChartBaseComponent } from './chart-base.component';
import { RappiderHeadingModule } from '../heading/heading.module';
import { RappiderParagraphModule } from '../paragraph/paragraph.module';

@NgModule({
  declarations: [
    RappiderChartBaseComponent
  ],
  imports: [
    CommonModule,
    RappiderHeadingModule,
    RappiderParagraphModule
  ],
  exports: [
    RappiderChartBaseComponent
  ]
})
export class RappiderChartBaseModule { }
