import { ButtonType, CrudFormCodeMirrorItem, CrudFormConfig, CrudViewFormItemType, JSON_CODEMIRROR_SETTINGS } from '@rappider/rappider-components/utils';

export const EDIT_CODE_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormCodeMirrorItem>{
      title: 'SHARED.CODE',
      fieldName: 'customCode',
      type: CrudViewFormItemType.CodeMirror,
      settings: JSON_CODEMIRROR_SETTINGS
    }
  ],
  submitButton: {
    text: 'SHARED.SAVE',
    size: 'default',
    type: ButtonType.Primary
  }
};
