import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export enum ProjectPackageAction {
  Delete = 'deleteProjectPackage'
}

export const PROJECT_PACKAGE_LIST_CONFIG_FOR_FRONTEND: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_VERSION',
      fieldName: 'version',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'Create Project Package For Frontend',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Emit
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'editProjectPackage',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-edit' }
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ]
};

export const PROJECT_PACKAGE_LIST_CONFIG_FOR_BACKEND: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_VERSION',
      fieldName: 'version',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'Create Project Package For Backend',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Emit
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'editProjectPackage',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-edit' }
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ]
};
