import { createAction, createReducer, on } from '@ngrx/store';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as PaymentActions from './payment.actions';

/* state key */
export const featureKey = 'payment';

/* state interface */
export interface PaymentState {
  isLoading: boolean;
  subscriptionAlertVisibility: boolean;
  subscriptionAlertVisibilityPermanently: boolean;
}

/* initial values */
export const initialState: PaymentState = {
  isLoading: false,
  subscriptionAlertVisibility: false,
  subscriptionAlertVisibilityPermanently: false
};

const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(PaymentActions.CreateCheckout, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PaymentActions.CreateCheckoutSuccessful, (state) => ({
    ...state,
    isLoading: false
  })),
  on(PaymentActions.CreateCheckoutFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(PaymentActions.SubscriptionAlertVisibility, (state, action) => ({
    ...state,
    subscriptionAlertVisibility: action.payload.subscriptionAlertVisibility,
    subscriptionAlertVisibilityPermanently: action.payload.subscriptionAlertVisibilityPermanently
  })),
  on(Logout, () => (initialState))
);
