<div class="filter-wrapper">
     <div class="json-schema"
          [ngClass]="{'json-schema-collapsed':jsonSchemaCollapsed}"
          joyrideStep="filterFirstStep"
          [title]="'DTFilterFindSchemaTitle' | translate"
          (done)="onDone()"
          [text]="'DTFilterFindSchemaContent' | translate">
          <div class="json-schema-title-bar">
               <h5>{{'Source Schema' | translate}}</h5>
               <span nz-icon
                     [nzType]="jsonSchemaCollapsed ? 'menu-unfold': 'menu-fold'"
                     nzTheme="outline"
                     (click)="jsonSchemaCollapsed = !jsonSchemaCollapsed"></span>
          </div>
          <div style="height: 70vh;">
               <rappider-monaco-code-editor [ngModel]="jsonSampleByJsonSchema"
                                            [options]="exampleJsonOptions"
                                            [autoFormat]="true"
                                            [showSelectedKeyPath]="true"
                                            [borderSettings]="{border: 'none'}"></rappider-monaco-code-editor>
          </div>
     </div>
     <div *ngIf="!jsonSchemaCollapsed"
          class="divider-vertical"></div>
     <div class="main-content"
          [ngClass]="{'w-100': jsonSchemaCollapsed}">
          <rappider-transformation-info-bar *ngIf="value"
                                            [(ngModel)]="value.operationField"
                                            [tree]="tree"
                                            (operationFieldChange)="onOperationFieldChange()">
          </rappider-transformation-info-bar>
          <rappider-filter-find-row-element *ngIf="value && tree"
                                            [tree]="currentTree"
                                            [jsonSchema]="jsonSchema"
                                            [(ngModel)]="value.operation"
                                            [functions]="functions"
                                            [visibility]="true"
                                            [filterOperationFlag]="filterOperationFlag"
                                            (showStepsFlag)="onShowStepsFlag($event)"
                                            (ngModelChange)="onChanges()"></rappider-filter-find-row-element>
     </div>
</div>
