import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  GetJiraDashboard = '[AdminComment] GetJiraDashboard',
  GetJiraDashboardSuccessful = '[AdminComment] GetJiraDashboardSuccessful',
  GetJiraDashboardFailure = '[AdminComment] GetJiraDashboardFailure'
}

/* get */
export const GetJiraDashboard = createAction(ActionTypes.GetJiraDashboard);

export const GetJiraDashboardSuccessful = createAction(
  ActionTypes.GetJiraDashboardSuccessful,
  props<{ data }>()
);

export const GetJiraDashboardFailure = createAction(
  ActionTypes.GetJiraDashboardFailure,
  props<{ error: any; key: string; date: number }>()
);
