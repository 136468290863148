import { CrudTableViewConfig, CrudViewColumnType, ButtonType, ActionBehavior } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { POP_CONFIRM_DELETE_ACTION } from '../../pop-confirm-button/pop-confirm-button-config';

export const OPENAPI_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.OPENAPI_ENDPOINT_LIST}/{{id}}`
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.OPENAPI_MODULE.IMPORT_OPENAPI_COMPONENT.IMPORT_OPENAPI_API',
      name: 'importOpenApiSpec',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' }
    }
  ],
  itemActions: [
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ]
};
