import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderListWrapperComponent } from './list-wrapper.component';
import { RappiderListGridModule } from '../list-grid/list-grid.module';
import { RappiderTitleToolbarModule } from '../title-toolbar/title-toolbar.module';
import { RappiderInputGroupModule } from '../input-group/input-group.module';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { RappiderCardCcsModule } from '../card-ccs/card-ccs.module';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    RappiderListWrapperComponent
  ],
  imports: [
    CommonModule,
    RappiderListGridModule,
    RappiderTitleToolbarModule,
    RappiderInputGroupModule,
    NzEmptyModule,
    RappiderCardCcsModule,
    FormsModule,
    NzGridModule,
    NzAlertModule,
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    NzModalModule
  ],
  exports: [
    RappiderListWrapperComponent
  ]
})
export class RappiderListWrapperModule { }
