import { ActionBehavior, CrudTableViewConfig, CrudViewColumnType, ListGridOrderType } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const PROJECT_SCRIPT_LIST_CONFIG: CrudTableViewConfig = {

  defaultSearchField: 'title',
  columns: [
    {
      title: 'id',
      fieldName: 'id',
      visible: false,
      type: CrudViewColumnType.Text
    },
    {
      title: 'Title',
      fieldName: 'title',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.EDIT_PROJECT_SCRIPT}/{{id}}`
    },
    {
      title: 'Type',
      fieldName: 'type',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Source Type',
      fieldName: 'sourceType',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Path',
      fieldName: 'path',
      type: CrudViewColumnType.Text
    },
    {
      title: 'File Name',
      fieldName: 'fileName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Created Date',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    },
    {
      title: 'id',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    }
  ],
  listActions: [],
  itemActions: [
    {
      text: 'Edit',
      name: 'EDIT',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.EDIT_PROJECT_SCRIPT}/{{id}}`,
      icon: { name: 'far fa-edit' },
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ],
  order: {
    fieldName: 'title',
    type: ListGridOrderType.Ascending
  }
};
