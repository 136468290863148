<section *ngIf="value && tree"
         class="filter-find-operation">
  <div class="row-item-area row-item-space"
       joyrideStep="logicItemFnFirstStep"
       [title]="'DTFilterFindItemThatCompareTitle' | translate"
       [text]="'DTFilterFindItemThatCompareContent' | translate">
    <div *ngIf="monacoVisibility" style="height: 50px;">
      <rappider-monaco-code-editor [(ngModel)]="value.sourceScript"
                                   [options]="editorOptions"
                                   [autoFormat]="true"
                                   [borderSettings]="{border: 'none'}"
                                   (ngModelChange)="onFunctionChange()"></rappider-monaco-code-editor>
    </div>
  </div>

  <div class="row-item-area row-item-space"
       joyrideStep="logicItemFnSecondStep"
       [title]="'DTFilterFindComparisonFunctionTitle' | translate"
       [text]="'DTFilterFindComparisonFunctionContent' | translate">
    <nz-select [(ngModel)]="value.function"
               [nzShowSearch]="true"
               nzAllowClear
               [nzPlaceHolder]="'SHARED.FUNCTION' | translate"
               (ngModelChange)="onFunctionChange()">
      <nz-option *ngFor="let option of functions"
                 [nzLabel]="option.title | translate"
                 [nzValue]="option.key"></nz-option>
    </nz-select>
  </div>

  <div class="row-item-area row-item-space"
       joyrideStep="logicItemFnThirdStep"
       [title]="'DTFilterFindItemToCompareTitle' | translate"
       [text]="'DTFilterFindItemToCompareContent' | translate">
    <div *ngIf="monacoVisibility" style="height: 50px;">
      <rappider-monaco-code-editor [(ngModel)]="value.targetScript"
                                   [options]="editorOptions"
                                   [autoFormat]="true"
                                   [borderSettings]="{border: 'none'}"
                                   (ngModelChange)="onFunctionChange()"></rappider-monaco-code-editor>
    </div>
  </div>

  <div class="delete-icon-area"
       joyrideStep="logicItemFnFourthStep"
       [title]="'DTFilterFindDeleteItemTitle' | translate"
       [text]="'DTFilterFindDeleteItemContent' | translate">
    <rappider-icon [name]="deleteIcon.name"
                   (click)="onDeleteButtonClick()"></rappider-icon>
  </div>
</section>
