import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconComponentConfig, PropertyType } from '@rappider/rappider-components/utils';
import { cloneDeep } from 'lodash';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { SchemaRowItem, DataTransformationFunction } from '../../../shared';
import { INITIAL_DATA } from '../../../shared/definitions/json-schema-row-initial-data';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'rappider-filter-find-row',
  templateUrl: './filter-find-row.component.html',
  styleUrls: ['./filter-find-row.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FilterFindRowComponent),
      multi: true
    }
  ]
})

export class FilterFindRowComponent implements ControlValueAccessor, OnChanges {
  /* select options for operator selectbox */
  @Input() functions: DataTransformationFunction[];
  /* json schema tree */
  @Input() tree: NzTreeNode[];
  @Input() logicItemAndFunctionsFlag: boolean;
  @Input() visibility: boolean;

  @Output() deleteButtonClick = new EventEmitter();
  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  showCodemirrorForObjectAndArray = false;
  monacoVisibility = false;

  selectedSourceFieldTypeAndFormat = {
    type: PropertyType.String,
    format: null,
    config: {
      placeholder: 'Selected Source'
    }
  };

  editorOptions = {
    theme: 'vs-default',
    language: 'jsonata',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 }
  };

  _value: SchemaRowItem = cloneDeep(INITIAL_DATA);

  deleteIcon: IconComponentConfig = {
    name: 'fa-solid fa-trash'
  };

  constructor(private readonly joyrideService: JoyrideService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.logicItemAndFunctionsFlag) {
      this.showSteps();
    }
    if (changes.visibility) {
      if (this.visibility) {
        setTimeout(() => {
          this.monacoVisibility = true;
        }, 50);
      } else if(this.visibility === false) {
        this.monacoVisibility = false;
      }

    }
  }

  showSteps() {
    if (this.logicItemAndFunctionsFlag) {
      this.joyrideService.startTour(
        { steps: ['logicItemFnFirstStep', 'logicItemFnSecondStep', 'logicItemFnThirdStep', 'logicItemFnFourthStep'] }
      );
    }
    this.logicItemAndFunctionsFlag = false;
    this.showStepsFlag.emit({ flag: this.logicItemAndFunctionsFlag, key: 'filter-find' });
  }

  get value() {
    return this._value;
  }

  set value(value: SchemaRowItem) {
    this._value = value;;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: SchemaRowItem): void {
    this._value = value || cloneDeep(INITIAL_DATA);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onDeleteButtonClick() {
    this.deleteButtonClick.emit();
  }

  onFunctionChange() {
    this.value = { ...this.value };
  }

  filterFunctionsBySourceFieldType() {
    return this.functions?.filter(item => item.type === this.selectedSourceFieldTypeAndFormat.type || item.type === 'any');
  }

}
