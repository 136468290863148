import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JiraControllerService } from '@rappider/rappider-sdk';

import * as AdminJiraDashboardActions from './jira-dashboard.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';


@Injectable()
export class AdminJiraDashboardEffects {
  constructor(
    private actions$: Actions,
    private jiraApi: JiraControllerService,
  ) { }

  getComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminJiraDashboardActions.GetJiraDashboard
      ),
      mergeMap(() => this.jiraApi.getJiraReport().pipe(
        map((data) => AdminJiraDashboardActions.GetJiraDashboardSuccessful({ data })),
        catchError((error) =>
          [
            AdminJiraDashboardActions.GetJiraDashboardFailure({ error, key: 'GetAdminJiraDasboard', date: Date.now() })
          ]
        )
      ))
    )
  );

}
