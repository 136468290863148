import { Category, PersonProjectWithRelations, Project, ProjectWithRelations } from '@rappider/rappider-sdk';
import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { Pagination } from '@rappider/shared/interfaces';
import * as ProjectActions from './project.actions';
import * as ActiveProjectActions from '../active-project-state/active-project.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { cloneDeep, orderBy } from 'lodash';
import { StateErrorService } from 'libs/shared/src/lib/services/state-error-service/state-error-service';

export const projectFeatureKey = 'project';

export interface State {
  data: Project[];
  pagination: Pagination;
  loading: boolean;
  isLoaded: boolean;
  isPersonProjectsLoaded: boolean;
  // TODO: Explain what is changeFavorite
  changeFavorite: boolean;
  personProjects: PersonProjectWithRelations[];
  loadingActiveProjectId: string;
  isPublicProjectsLoading: boolean;
  publicProjectCategoryIds: string[];
  publicProjectsCount: number;
  isPublicProjectsLoaded: boolean;
  isPublicProjectsTagsLoaded: boolean;
  publicProjects: ProjectWithRelations[];
  publicProjectCategories: Category[];
  lastError: {
    error: any;
    errorKey: string;
    timestamp?: string;
  };
  deleteModalOkLoading: boolean;
}

export const initialState: State = {
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    totalCount: null,
    totalPageNumber: null
  },
  loading: false,
  isLoaded: false,
  isPersonProjectsLoaded: false,
  changeFavorite: false,
  personProjects: null,
  loadingActiveProjectId: null,
  lastError: null,
  isPublicProjectsLoading: true,
  publicProjects: null,
  publicProjectCategoryIds: [],
  publicProjectsCount: null,
  isPublicProjectsLoaded: false,
  isPublicProjectsTagsLoaded: false,
  publicProjectCategories: null,
  deleteModalOkLoading: false
};

export function reducer(
  state: State = initialState,
  action: ProjectActions.Actions | AuthenticationActions.Actions | ActiveProjectActions.Actions
): State {
  switch (action.type) {

    case ProjectActions.ActionTypes.GetProjects:
      return {
        ...state,
        loading: true,
        isLoaded: false,
        isPersonProjectsLoaded: false
      };

    case ProjectActions.ActionTypes.GetProjectsFailure:
      return {
        ...state,
        loading: false,
        ...StateErrorService.handleFailure(state, action)
      };

    case ProjectActions.ActionTypes.GetPublicProjects:
      return {
        ...state,
        isPublicProjectsLoading: true
      };

    case ProjectActions.ActionTypes.GetPublicProjectsSuccessful:
      return {
        ...state,
        publicProjects: action.payload.publicProjects,
        isPublicProjectsLoading: false,
        isPublicProjectsLoaded: true
      };

    case ProjectActions.ActionTypes.GetPublicProjectsPagination:
      return {
        ...state,
        isPublicProjectsLoading: true
      };

    case ProjectActions.ActionTypes.GetPublicProjectsPaginationSuccessful:
      return {
        ...state,
        publicProjectsCount: action.payload.count,
        isPublicProjectsTagsLoaded: true
      };

    case ProjectActions.ActionTypes.GetPublicProjectsCategoriesSuccessful:
      return {
        ...state,
        publicProjectCategories: action.payload.publicProjectsCategories,
        isPublicProjectsLoading: false
      };

    case ProjectActions.ActionTypes.GetPublicProjectsCategoryIds:
      return {
        ...state,
        publicProjectCategoryIds: action.payload.publicProjectCategoryIds,
        isPublicProjectsLoading: false
      };

    case ProjectActions.ActionTypes.AddCategoryToPublicProjectCategories:
      return {
        ...state,
        publicProjectCategories: orderBy([
          ...state.publicProjectCategories,
          action.payload.projectCategory
        ], 'createdDate', 'asc')
      };

    case ProjectActions.ActionTypes.RemoveCategoryFromPublicProjectCategories:
      return {
        ...state,
        publicProjectCategories: state.publicProjectCategories.filter(c => c.id !== action.payload.projectCategory.id)
      };

    case ProjectActions.ActionTypes.UpdateCategoryFromPublicProjectCategories:
      return {
        ...state,
        publicProjectCategories: orderBy([
          ...state.publicProjectCategories.filter(c => c.id !== action.payload.projectCategory.id),
          action.payload.projectCategory
        ], 'createdDate', 'asc')
      };

    case ProjectActions.ActionTypes.GetPublicProjectsFailure:
      return {
        ...state,
        isPublicProjectsLoading: false,
        ...StateErrorService.handleFailure(state, action)
      };

    case ProjectActions.ActionTypes.SetProjects:
      return {
        ...state,
        data: action.payload.projects,
        loading: false,
        isLoaded: true
      };

    case ProjectActions.ActionTypes.GetPersonProjectsSuccessful:
      return {
        ...state,
        loading: false,
        isPersonProjectsLoaded: true,
        personProjects: action.payload.personProjects
      };

    case ProjectActions.ActionTypes.SetPagination:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        }
      };

    case ProjectActions.ActionTypes.CreateProject:
      return {
        ...state,
        loading: true
      };

    case ProjectActions.ActionTypes.CreateProjectSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.project
        ],
        personProjects: [
          ...state.personProjects,
          action.payload.project
        ],
        loading: false
      };

    case ProjectActions.ActionTypes.CreateProjectFailure:
      return {
        ...state,
        loading: false
      };

    case ProjectActions.ActionTypes.SetAsActiveProjectThemeSuccessful: {
      const existingProject = cloneDeep(state.data?.find(datum => datum.id === action.payload.activeProjectId));
      existingProject.activeProjectThemeId = action.payload.projectThemeId;
      return {
        ...state,
        loading: false,
        data: [
          ...state.data.filter(datum => datum.id !== action.payload.activeProjectId),
          existingProject
        ]
      };
    };

    case ProjectActions.ActionTypes.DeleteProject: {
      return {
        ...state,
        deleteModalOkLoading: true
      };
    };


    case ProjectActions.ActionTypes.DeleteProjectSuccessful: {
      return {
        ...state,
        loading: false,
        data: [
          ...state.data.filter(project => project.id !== action.payload.projectId),
        ],
        personProjects: [
          ...state.data.filter(project => project.id !== action.payload.projectId)
        ],
        deleteModalOkLoading: false
      };
    };
    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case ProjectActions.ActionTypes.FavoritePersonProject: {
      return {
        ...state,
        changeFavorite: false
      };
    }

    case ProjectActions.ActionTypes.FavoritePersonProjectSuccessful: {
      let favoritedPersonProject = state.personProjects.find(project => project.projectId === action.payload.projectId);
      favoritedPersonProject = {
        ...favoritedPersonProject,
        isFavorited: action.payload.isFavorited
      };
      return {
        ...state,
        personProjects: [
          ...state.personProjects.filter(project => project.projectId !== action.payload.projectId),
          favoritedPersonProject
        ],
        changeFavorite: true
      };
    }

    case ProjectActions.ActionTypes.FavoritePersonProjectFailure:
      return StateErrorService.handleFailure(state, action);

    case ProjectActions.ActionTypes.UnFavoritePersonProject: {
      return {
        ...state,
        changeFavorite: false
      };
    }

    case ProjectActions.ActionTypes.UnFavoritePersonProjectSuccessful: {
      let unfavoritedPersonProject = state.personProjects.find(project => project.projectId === action.payload.projectId);
      unfavoritedPersonProject = {
        ...unfavoritedPersonProject,
        isFavorited: action.payload.isFavorited
      };
      return {
        ...state,
        personProjects: [
          ...state.personProjects.filter(project => project.projectId !== action.payload.projectId),
          unfavoritedPersonProject
        ],
        changeFavorite: true
      };
    }

    case ProjectActions.ActionTypes.UnFavoritePersonProjectFailure:
      return StateErrorService.handleFailure(state, action);

    case ProjectActions.ActionTypes.GetPersonProjectByProjectIdSuccessful:
      return {
        ...state,
        personProjects: [
          ...state.personProjects,
          action.payload.personProject
        ]
      };

    case ProjectActions.ActionTypes.UpdateProject:
      return {
        ...state,
        loading: true
      };

    case ProjectActions.ActionTypes.UpdateProjectSuccessful:
      {
        const updatedProject = {
          ...cloneDeep(state.data.find(project => project.id === action.payload.projectId)),
          ...action.payload.project
        };

        return {
          ...state,
          data: orderBy([
            ...state.data.filter(project => project.id !== action.payload.projectId),
            updatedProject
          ], 'createdDate', 'asc'),
          loading: false
        };
      }

    case ProjectActions.ActionTypes.UpdateProjectFailure:
      return {
        ...state,
        loading: false
      };

    case ActiveProjectActions.ActionTypes.GetActiveProject:
      return {
        ...state,
        loadingActiveProjectId: action.payload.projectId
      };

    case ActiveProjectActions.ActionTypes.SetActiveProject:
      {
        if (action.payload.project?.id) {
          return {
            ...state,
            loadingActiveProjectId: null
          };

        } else {
          return state;
        }
      }

    case ProjectActions.ActionTypes.ProjectError:
      return {
        ...state,
        loading: false,
        lastError: {
          error: action.payload.error,
          errorKey: action.payload.errorOn
        }
      };

    default:
      return state;
  }
}
