import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { UsernameType, PATH_DEFINITIONS, RAPPIDER_THEME_SETTINGS, AppRightSidebarTab } from '@rappider/shared/definitions';
import { BrowserStorageManagementService, LanguageService, RoutingService, ScrollService } from '@rappider/services';

import { UserInterface, ProjectInterface, PageInterface } from '@rappider/api-sdk';
import { Logout, SendVerifyEmail, SendVerifySms } from 'libs/authentication/src/lib/state/authentication.actions';

import {
  OTHER_FEATURES_MENU_ITEM,
  PERSON_EMAIL_VERIFY_ALERT_CONFIG, PERSON_PHONE_NUMBER_VERIFY_ALERT_CONFIG,
  PROJECT_MENU_CONFIG,
  WORKSPACE_FOOTER_MENU,
  WORKSPACE_MENU, WORKSPACE_MENU_DROPDOWN, WORKSPACE_MENU_ITEM_PAGE_MENU_MAP
} from '@rappider/shared/configs';

import { AlertConfig, Menu, MenuActionBehavior } from '@rappider/rappider-components/utils';
import { ThemeMode } from '@rappider/models';
import { Person } from '@rappider/rappider-sdk';
import { DeployStatus } from 'libs/project/src/lib/components/live-preview/utils/deploy-status.enum';
import { Navigate, sidebarToggleAnimation } from '@rappider/shared';
import { ChangeAppSearchVisibility, ToggleRightSidebarVisibility } from './state/app.actions';
import { homePricingConfigApp } from './utils/home-pricing-app.config';
import { SubscriptionAlertVisibility } from 'libs/payment/src/lib/state/payment.actions';
import { Permission } from './components/header/utils/admin-dashboard-permissions.enum';
import { TierType } from 'libs/authentication/src/lib/utils/tier-type.enum';

@Component({
  selector: 'rappider-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    sidebarToggleAnimation
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  /* authentication variables */
  authenticatedUser: UserInterface;
  activePerson: Person;
  PERSON_VERIFICATION_ALERT_CONFIG: AlertConfig;
  isMenuVisible = true;
  isPersonVerified = false;
  isAlertVisible = true;
  isFooterVisible = true;
  isHeaderVisible = true;
  rightSidebarVisibility = false;
  isLayoutsVisible = true;
  isHomePricingVisible = false;
  isHomePricingVisiblePermanently = true;
  activeRightSidebarTabName = null;
  noInnerContentPadding = true;
  displayLivePreviewNotification = false;
  pagesMenu = null;
  workSpaceMenu = WORKSPACE_MENU;
  workSpaceMenuDropdown = WORKSPACE_MENU_DROPDOWN;
  workSpaceFooterMenu = WORKSPACE_FOOTER_MENU;
  rappiderThemeSettings = RAPPIDER_THEME_SETTINGS;
  title = 'rappider';
  subscriptions: Subscription[] = [];
  activeProject: ProjectInterface;
  pages: PageInterface[];
  isWorkSpaceMenuCollapsed = true;
  displayCodeView = false;
  projectListPath = PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH;
  selectedTheme: ThemeMode = ThemeMode.Dark;
  // page title saved on the route data
  routePageTitle: string;
  /* flag to enable the page title on the header */
  showPageTitle = false;

  projectFeatures = PROJECT_MENU_CONFIG;

  deployAlertConfig = {
    title: '',
    description: '',
    titleIcon: ''
  };

  /**
   * The deployment status indicates whether the initiation of the deployment process
   * was successful or if an error occurred during its commencement;
   * it does not imply the completion or non-completion of the deployment itself.
   * @type {DeployStatus}
   * @memberof AppComponent
   */
  deployStatus: DeployStatus;
  DeployStatus = DeployStatus;

  /* active route path */
  activePath: string;

  appSearchVisibility;

  homePricingConfigApp = homePricingConfigApp;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.appSearchVisibility) {
      this.store.dispatch(new ChangeAppSearchVisibility());
    }
    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'f') {
      this.store.dispatch(new ChangeAppSearchVisibility());
      return false;
    }
  }

  constructor(
    // TODO: Fix type issue below
    private store: Store<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    private router: Router,
    private routingService: RoutingService,
    private scrollService: ScrollService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private browserStorageManagementService: BrowserStorageManagementService
  ) { }

  ngOnInit(): void {
    this.getWorkSpaceMenu();
    this.subscribeToData();
    this.setThemeBySystemTheme();
  }

  /**
   *
   *
   * @memberof AppComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Init application theme based on the system preference
   * Flow as below
   * 1 - If there is a saved mode in the person data - use this as the initial value
   * 2 - If there is no saved mode, Check for the preferred device theme
   * 3 - If device theme is dark, set the init value to `dark`
   * 4 - Else set the default value to `light`
   */
  setThemeBySystemTheme() {
    // remove old class
    document.body.classList.remove(this.selectedTheme);
    if (this.activePerson?.preferredTheme || this.browserStorageManagementService.getLocalStorageItem('preferredTheme')) {
      if (this.activePerson?.preferredTheme) {
        this.selectedTheme = this.activePerson.preferredTheme as ThemeMode;
      } else {
        this.selectedTheme = this.browserStorageManagementService.getLocalStorageItem('preferredTheme') as ThemeMode;
      }
      // add new class
      document.body.classList.add(this.selectedTheme);
      this.browserStorageManagementService.setLocalStorageItem('preferredTheme', this.selectedTheme);
    } else {
      // const deviceMode = window.matchMedia('(prefers-color-scheme: dark)');
      // this.selectedTheme = deviceMode.matches ? ThemeMode.Dark : ThemeMode.Light;
      this.selectedTheme = ThemeMode.Dark;
      // add new class
      document.body.classList.add(this.selectedTheme);
      this.browserStorageManagementService.setLocalStorageItem('preferredTheme', this.selectedTheme);

    }
  }

  /**
   * Subscribe all data
   *
   * @memberof AppComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToNavigation(),
      this.subscribeToActivePerson(),
      this.subscribeToUser(),
      this.subscribeToActiveProject(),
      this.subscribeToDeployStatus(),
      this.subscribeToAppSearchVisibility(),
      this.subscribeToRightSidebarTabName(),
      this.subscribeToRightSidebarVisibility(),
      this.subscribeToSubscriptionAlertVisibility(),
      this.subscribeToSubscriptionAlertVisibilityPermanently()
    ];
  }

  updateWorkspaceMenuHomePageItemPath() {
    const projectHomePath = this.activeProject?.id
      ? `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`
      : PATH_DEFINITIONS.PROJECTS.PROJECT_ROOT;

    const updateMenuItemPath = (menuItem) => {
      if (menuItem) {
        menuItem.redirectUrl = projectHomePath;
      }
    };

    updateMenuItemPath(this.workSpaceMenu.items.find(m => m.key === 'project-home'));
    updateMenuItemPath(this.workSpaceMenuDropdown.items.find(m => m.key === 'project-home'));

    this.workSpaceMenu = { ...this.workSpaceMenu };
    this.workSpaceMenuDropdown = { ...this.workSpaceMenuDropdown };
  }

  /**
   * subscribes to router event to get information about path
   *
   * @returns
   * @memberof AppComponent
   */
  subscribeToNavigation() {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isAlertVisible = !(this.routingService.getRouteDataByFieldName('alertVisibility') === false);
        this.isMenuVisible = !(this.routingService.getRouteDataByFieldName('isSideMenuVisible') === false);
        this.isFooterVisible = !(this.routingService.getRouteDataByFieldName('isFooterVisible') === false);
        this.isHeaderVisible = !(this.routingService.getRouteDataByFieldName('isHeaderVisible') === false);
        this.noInnerContentPadding = !!this.routingService.getRouteDataByFieldName('noInnerContentPadding');
        this.displayLivePreviewNotification = !(this.routingService.getRouteDataByFieldName('displayLivePreviewNotification') === false);
        this.isLayoutsVisible = !(this.routingService.getRouteDataByFieldName('isLayoutsVisible') === false);
        this.isHomePricingVisible = this.isHomePricingVisiblePermanently ? (this.routingService.getRouteDataByFieldName('isHomePricingVisible') === false) : false;
        this.activePath = event.url;

        let currentRoute = this.activatedRoute.snapshot;
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }

        // Now, currentRoute is the deepest active child route.
        const data = currentRoute.data;
        this.routePageTitle = data?.title || null;
        this.showPageTitle = data?.showPageTitle;
      }
    });
  }

  subscribeToRightSidebarTabName() {
    return this.store.select(state => state.app.rightSidebarTabName).subscribe(rightSidebarTabName => {
      this.activeRightSidebarTabName = rightSidebarTabName;
    });
  }

  subscribeToRightSidebarVisibility() {
    return this.store.select(state => state.app.rightSidebarVisibility).subscribe(rightSidebarVisibility => {
      this.rightSidebarVisibility = rightSidebarVisibility;
    });
  }

  /**
   * Subscribe active person
   *
   * @returns
   * @memberof AppComponent
   */
  subscribeToActivePerson() {
    return this.store.select(state => state.auth?.activePerson)
      .subscribe(activePerson => {
        if (activePerson) {
          this.isPersonVerified = activePerson?.isVerified && activePerson?.interest;
          this.activePerson = activePerson;
          // set active person to root DOM for Zendesk
          window['activePerson'] = activePerson;
          this.setThemeBySystemTheme();
        }
      });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      const oldActiveProjectId = this.activeProject?.id;
      if (oldActiveProjectId !== activeProject?.id && activeProject?.id) {
        this.activeProject = activeProject;
      }
      if (!activeProject) {
        this.activeProject = null;
      }
      this.updateWorkspaceMenuHomePageItemPath();
    });
  }

  subscribeToDeployStatus() {
    return this.store.select(state => state.deployManagement?.deployStatus).subscribe(status => {
      this.deployStatus = status;
      if (this.deployStatus === DeployStatus.Success) {
        this.deployAlertConfig = {
          title: 'Project Deployment Started',
          description: 'Click here to go to live preview page',
          titleIcon: 'info-circle'
        };
      } else {
        this.deployAlertConfig = {
          title: 'Error',
          description: 'There was an error while deploying project.',
          titleIcon: 'close-circle'
        };
      }
    });
  }

  subscribeToPages() {
    return this.store.select(state => state.page?.pages)
      .subscribe(pages => {
        if (pages) {
          this.pages = pages;
          this.updatePageCount();
        }
      });
  }

  /**
     * Subscribe authenticated user
     *
     * @returns
     * @memberof AppComponent
     */
  subscribeToUser() {
    return this.store.select(state => state.auth)
      .subscribe(auth => {
        this.authenticatedUser = auth.user;
        const activePerson = auth.activePerson;
        /* reset person verification alert config */
        this.PERSON_VERIFICATION_ALERT_CONFIG = null;
        if (this.authenticatedUser && activePerson) {
          if (this.authenticatedUser.usernameType === UsernameType.Email) {
            /* set person verification alert config */
            this.PERSON_VERIFICATION_ALERT_CONFIG = activePerson?.interest ? PERSON_EMAIL_VERIFY_ALERT_CONFIG : null;
          } else if (this.authenticatedUser.usernameType === UsernameType.PhoneNumber) {
            /* set person verification alert config */
            this.PERSON_VERIFICATION_ALERT_CONFIG = activePerson?.interest ? PERSON_PHONE_NUMBER_VERIFY_ALERT_CONFIG : null;
          }
        }
        if (auth.isPermissionLoaded && auth.tenant) {
          if (auth.permissions.includes(Permission.Admin)) {
            this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: false, subscriptionAlertVisibilityPermanently: false } }));
          } else if (auth.tenant.role.key === TierType.FreeTier) {
            this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: true, subscriptionAlertVisibilityPermanently: true } }));
          } else if (auth.tenant.role.key === TierType.BuilderTier) {
            this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: false, subscriptionAlertVisibilityPermanently: false } }));
          }
          this.updateWorkspaceFooterMenu(auth.permissions.includes(Permission.Admin));
        }
      });
  }

  subscribeToAppSearchVisibility() {
    return this.store.select(state => state.app.appSearch.visibility)
      .subscribe(visibility => this.appSearchVisibility = visibility);
  }

  subscribeToSubscriptionAlertVisibility() {
    return this.store.select(state => state.payment.subscriptionAlertVisibility)
      .subscribe(visibility => this.isHomePricingVisible = visibility);
  }

  subscribeToSubscriptionAlertVisibilityPermanently() {
    return this.store.select(state => state.payment.subscriptionAlertVisibilityPermanently)
      .subscribe(visibility => this.isHomePricingVisiblePermanently = visibility);
  }

  subscribeToQueryParams() {
    return this.store.select(state => state.router.queryParams)
      .subscribe(queryParams => {
        const queryParam = queryParams['activeRightSidebarTab'];
        this.handleRightSidebarVisibilityByQueryParam(queryParam);
      });
  }

  /**
   * Add develop menu to the workspace menu
   * Develop menu redirects to active project detail page
   * @memberof AppComponent
   */
  insertOtherFeaturesToWorkspaceMenu() {
    const otherFeatureMenuItem: Menu = {
      ...OTHER_FEATURES_MENU_ITEM,
      children: this.projectFeatures.map(feature => <any>{
        label: feature.title,
        tooltip: feature.title,
        key: feature.title,
        children: feature?.subFeatures?.map(subFeature => ({
          label: subFeature.title,
          tooltip: subFeature.title,
          key: subFeature.title,
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: subFeature.path
        }))
      })
    };

    /* set the menu items by inserting the develop menu to the index */
    const workspaceMenuItems = [
      ...WORKSPACE_MENU.items,
      otherFeatureMenuItem
    ];

    /* update the workSpaceMenu */
    this.workSpaceMenu = {
      ...this.workSpaceMenu,
      items: workspaceMenuItems
    };
  }

  updateWorkspaceFooterMenu(isAdmin: boolean) {
    const uiPageTemplatesIndex = this.workSpaceFooterMenu.items[0].children.findIndex(
      item => item.key === 'ui-page-templates'
    );
    const languageMenuIndex = this.workSpaceFooterMenu.items.findIndex(
      item => item.key === 'language'
    );

    if (isAdmin && uiPageTemplatesIndex === -1) {
      this.workSpaceFooterMenu.items[0].children.push({
        label: 'UI Page Templates',
        tooltip: 'UI Page Templates',
        key: 'ui-page-templates',
        showLabel: true,
        icon: {
          name: 'fa-thin fa-files'
        },
        actionBehavior: MenuActionBehavior.Emit,
        redirectUrl: PATH_DEFINITIONS.PAGE_TEMPLATES.PAGE_TEMPLATES_LIST_PATH
      });
    } else if (!isAdmin && uiPageTemplatesIndex > -1) {
      this.workSpaceFooterMenu.items[0].children.splice(uiPageTemplatesIndex, 1);
    }

    if (languageMenuIndex > -1 && this.workSpaceFooterMenu.items[languageMenuIndex].children.length < 2) {
      this.workSpaceFooterMenu.items.splice(languageMenuIndex, 1);
    }

    this.workSpaceFooterMenu = { ...this.workSpaceFooterMenu };
  }

  workSpaceMenuItemClicked(clickedMenuItem: Menu) {
    // if (clickedMenuItem?.key === 'turkish') {
    //   this.languageService.changeLanguage('tr');
    // } else
    if (clickedMenuItem?.key === 'english') {
      this.languageService.changeLanguage('en');
    } else if (clickedMenuItem?.key === 'logout') {
      this.logout();
    } else {
      this.switchPageMenuTo(clickedMenuItem.key);
      this.navigateTo(clickedMenuItem.redirectUrl, clickedMenuItem.queryParams);
    }
  }

  navigateTo(path: string, queryParams?: Record<string, unknown>) {
    this.router.navigate([path], { queryParams: queryParams });
  }

  /**
   * Switch / Change page menu to
   *
   * @param {string} menuKey
   * @memberof AppComponent
   */
  switchPageMenuTo(menuKey: string) {
    if (WORKSPACE_MENU_ITEM_PAGE_MENU_MAP[menuKey]) {
      this.pagesMenu = { ...WORKSPACE_MENU_ITEM_PAGE_MENU_MAP[menuKey] };
    } else {
      this.pagesMenu = null;
    }
  }

  updatePageCount() {
    if (this.pages?.length) {
      const contentMenu = this.pagesMenu.items.find(m => m.key === 'content');
      if (contentMenu?.children?.length) {
        const pagesMenuItem = contentMenu.children.find(m => m.key === 'pages');
        this.setMenuTag(pagesMenuItem, this.pages.length.toString(), 'processing');
      }
    }
  }

  /* TODO: Move to menu service. This shouldnt be in this component  */
  setMenuTag(menuItem: Menu, content: string, tagColor?: string) {
    menuItem.tag = content;
    menuItem.tagColor = tagColor;
  }

  resendVerifyEmailOrSms() {
    if (this.authenticatedUser?.usernameType === UsernameType.Email) {
      this.store.dispatch(new SendVerifyEmail());
      this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.AUTH.ACCOUNT_VERIFICATION_PATH }));
    } else if (this.authenticatedUser.usernameType === UsernameType.PhoneNumber) {
      this.store.dispatch(new SendVerifySms());
      this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.AUTH.VERIFY_PHONE_NUMBER_PATH }));
    }
  }

  onRouteChange() {
    /* whenever route changes, page will scroll to top */
    this.scrollService.scrollToTop();
  }

  onMenuToggled(toggled: boolean) {
    this.isWorkSpaceMenuCollapsed = !!toggled;
  }

  onCodeViewToggled(toggled: boolean) {
    this.displayCodeView = !!toggled;
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  menuWidth() {
    const workspaceMenuWidth = this.pagesMenu ?
      this.rappiderThemeSettings.menuWidthWithPageMenu :
      (this.isWorkSpaceMenuCollapsed ?
        this.rappiderThemeSettings.menuWidthWithoutPageMenu : this.rappiderThemeSettings.menuWidthWhenWorkspaceMenuExpanded);
    return !this.isLayoutsVisible ? this.rappiderThemeSettings.menuWidthWhenNoMenu : workspaceMenuWidth;
  }

  onRightSidebarToggle(tabName: AppRightSidebarTab) {
    this.store.dispatch(new ToggleRightSidebarVisibility());
  }

  validateRightSidebarVisibilityByActivePath() {
    const splittedPath = this.activePath.split('?');
    const route = splittedPath[0];
    return route !== '/ai/assistant';
  }

  handleRightSidebarVisibilityByQueryParam(tabName: string) {
    if (tabName) {
      this.activeRightSidebarTabName = tabName;
      this.rightSidebarVisibility = true;
      document.body.classList.add('right-sidebar-active');
    } else {
      this.activeRightSidebarTabName = null;
      this.rightSidebarVisibility = false;
      document.body.classList.remove('right-sidebar-active');
    }
  }

  goToLivePreviewPage() {
    const url = `${PATH_DEFINITIONS.PROJECTS.PROJECT_LIVE_PREVIEW_PATH}/${this.activeProject.id}`;
    this.store.dispatch(new Navigate({
      url
    }));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.getWorkSpaceMenu();
  }

  getWorkSpaceMenu() {
    if (window.innerHeight < (this.isHomePricingVisible ? 1000 : 900)) {
      return this.workSpaceMenuDropdown;
    } else {
      return this.workSpaceMenu;
    }
  }

  pricingCloseButtonClick() {
    this.isHomePricingVisible = false;
    this.isHomePricingVisiblePermanently = false;
    this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: false, subscriptionAlertVisibilityPermanently: true } }));
  }

  navigateToPayment() {
    this.router.navigateByUrl('/payment');
  }

}
