<section *ngIf="isLoading && !isLoaded">
  <nz-skeleton [nzActive]="true"
               [nzLoading]="isLoading"
               [nzTitle]="false"
               [nzParagraph]="{ rows: 2 }">
  </nz-skeleton>
</section>
<rappider-title-toolbar *ngIf="!isLoading || isLoaded"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <nz-tabset nzLinkRouter
             nzType="card"
             nzAnimated="true"
             nzTabPosition="top"
             nzSize="default"
             class="card-container">
    <nz-tab>
      <a *nzTabLink
         class="tab-link"
         nz-tab-link
         [routerLink]="[]"
         [queryParams]="QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB"
         queryParamsHandling="merge">
        {{ 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.DATA_FIELDS' | translate }}
      </a>
      <rappider-list-grid [config]="DATA_FIELD_LIST_CONFIG"
                          [data]="activeProjectModel?.fields"
                          [loading]="!(!isProjectModelFieldsLoading && isProjectModelFieldsLoaded) || isFieldsLoadingForModel"
                          (listActionClick)="navigateCreateDataFieldPage()"
                          (columnActionClick)="onDeleteDataFieldClick($event)"
                          (listActionDropdownItemClick)="onListActionDropdownItemClick($event)"
                          (orderChange)="onOrderChange($event)"></rappider-list-grid>
    </nz-tab>

    <nz-tab>
      <a *nzTabLink
         class="tab-link"
         nz-tab-link
         [routerLink]="[]"
         [queryParams]="QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.ENDPOINTS_TAB"
         queryParamsHandling="merge">
        {{ 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.ENDPOINTS' | translate }}
      </a>
      <rappider-custom-endpoint-list></rappider-custom-endpoint-list>
    </nz-tab>

    <nz-tab>
      <a *nzTabLink
         class="tab-link"
         nz-tab-link
         [routerLink]="[]"
         [queryParams]="QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.RELATIONS_TAB"
         queryParamsHandling="merge">
        {{ 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.RELATIONS' | translate }}
      </a>
      <rappider-project-model-relation-list></rappider-project-model-relation-list>
    </nz-tab>
  </nz-tabset>
</section>

<rappider-modal [(visible)]="isDataSchemaFieldModalVisible"
                [fullScreen]="true"
                [title]=" activeDataSchema?.name + ' Data Schema Fields'"
                [footer]="currentStep === 0 ? firstStepFooter : secondStepFooter"
                (cancelClick)="toggleEditDataSchemaFieldModalVisibility()"
                (okClick)="toggleEditDataSchemaFieldModalVisibility()">
  <rappider-data-schema-display [dataSchemas]="dataSchemas"
                                [activeDataSchema]="activeDataSchema"
                                [projectModel]="activeProjectModel"
                                [replaceExistingCrudPages]="replaceExistingCrudPages"
                                [layouts]="layouts"
                                [isLoading]="isDataSchemasLoading || isCrudPagesLoading"
                                [mode]="dataSchemaDisplayComponentMode"
                                (currentStepChange)="onCurrentStepChange($event)"></rappider-data-schema-display>

  <ng-template #firstStepFooter>
    <div class="footer-buttons">
      <!-- <div class="footer-button-help">
        <rappider-button [icon]="ActionButtonConfig.HelpButton.icon"
                         nz-tooltip="Help"
                         (confirm)="onClickHelp()"></rappider-button>
      </div> -->
      <div class="footer-action-buttons">
        <rappider-button [text]="ActionButtonConfig.SkipButton.text"
                         (confirm)="onClickSkip()"></rappider-button>

        <rappider-button [text]="ActionButtonConfig.SaveAndContinueButton.text"
                         [type]="ActionButtonConfig.SaveAndContinueButton.type"
                         [loading]="isDataSchemasLoading || isCrudPagesLoading"
                         (confirm)="onClickSave()"></rappider-button>
      </div>
    </div>
  </ng-template>
  <ng-template #secondStepFooter>
    <div class="footer-buttons">
      <!-- <div class="footer-button-help">
        <rappider-button [icon]="ActionButtonConfig.HelpButton.icon"
                         nz-tooltip="Help"
                         (confirm)="onClickHelp()"></rappider-button>
      </div> -->
      <div class="footer-action-buttons">
        <rappider-button [text]="ActionButtonConfig.BackButton.text"
                         (confirm)="onClickBack()"></rappider-button>
        <rappider-button [text]="ActionButtonConfig.GenerateCrudPagesButton.text"
                         [type]="ActionButtonConfig.GenerateCrudPagesButton.type"
                         [loading]="isDataSchemasLoading || isCrudPagesLoading"
                         [emitWithoutPopconfirm]="ActionButtonConfig.GenerateCrudPagesButton.emitWithoutPopconfirm"
                         [popconfirmTitle]="activeProjectModel?.isEachCRUDPageGenerated ?
                     'Crud pages generated already, are you sure to generate again?' : 'Are you sure to generate crud pages with this config?'"
                         (confirm)="generateCRUDPages()"></rappider-button>
      </div>
    </div>
  </ng-template>
</rappider-modal>

<rappider-modal [(visible)]="showReplaceModal"
                [width]="'85%'"
                [title]="'Replace Existing Crud Pages?' | translate"
                [footer]="replaceFooter"
                [okText]="'Replace'"
                (cancelClick)="closeReplaceModal()"
                (okClick)="openGenerateCrudPageModal()">
  {{'Currently this model has crud pages, do you want to replace? Warning: the current crud page will be deleted.' |
  translate}}
  <ng-template #replaceFooter>
    <button nz-button
            nzType="default"
            (click)="changeReplacePageOption(true)">{{'Replace' | translate}}</button>
    <button nz-button
            nzType="primary"
            (click)="changeReplacePageOption(false)">{{'Add As New Page' | translate}}</button>
  </ng-template>
</rappider-modal>
