<div>
  <rappider-list-wrapper [mainTitleConfig]="mainTitleConfig"
                         [titleBarActionButtons]="[]"
                         [listGridConfig]="listGridConfig"
                         [allowDataImport]="true"
                         [data]="testData"></rappider-list-wrapper>
</div>

<div *ngIf="false">

  <!-- Page Title -->
  <section>
    <h1>
      State Dashboard KPI Details
    </h1>
  </section>

  <section class="page-tabs">
    <!-- TABS -->
    <nz-tabset nzCentered>

      <!-- SUMMARY TAB -->
      <nz-tab nzTitle="SUMMARY"
              [nzForceRender]="true">
        <div class="row">
          <!-- SUMMARY CHART 1 -->
          <section class="col-4">
            <rappider-chart-base [config]="totalEnrollmentPieChartConfig"
                                 [content]="totalEnrollmentPieChartContent"></rappider-chart-base>
          </section>

          <!-- SUMMARY CHART 2 -->
          <section class="col-4">
            <h2 class="card-title text-left">Enrollment Trends</h2>
            <br><br>
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <h3 class="trend-value">Benefit Year TD <span>20%</span></h3>
                <i class="fa-solid fa-down trend-icon fa-2x"></i>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <h3 class="trend-value">Last Year Month <span>20.2%</span></h3>
                <i class="fa-solid fa-down trend-icon fa-2x"></i>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <h3 class="trend-value">Since Last Month <span>0.4%</span></h3>
                <i class="fa-solid fa-down trend-icon fa-2x"></i>
              </li>
            </ul>
          </section>

          <!-- SUMMARY CHART 3 -->
          <section class="col-4">
            <rappider-chart-base [config]="applicationPieConfig"
                                 [content]="applicationPieConfigContent"></rappider-chart-base>
          </section>

          <!-- SUMMARY CHART 4 -->
          <section class="col-6">
            <rappider-chart-base [config]="newProgramEnrollmentBarConfig"
                                 [content]="newProgramEnrollmentBarContent"></rappider-chart-base>
          </section>

          <!-- SUMMARY CHART 5 -->
          <section class="col-6">
            <rappider-chart-base [config]="serviceUtilizationToDateBarConfig"
                                 [content]="serviceUtilizationToDateBarContent"></rappider-chart-base>
          </section>


        </div>
      </nz-tab>
      <!-- END OF SUMMARY TAB -->

      <!-- APPLICATIONS TAB -->
      <nz-tab nzTitle="APPLICATIONS"
              [nzForceRender]="true">
        <div class="row">
          <section class="col-6">
            <rappider-chart-base [config]="newProgramEnrollmentConfig"
                                 [content]="newProgramEnrollmentContent"></rappider-chart-base>
          </section>

          <section class="col-6">
            <rappider-chart-base [config]="providerFeedback"
                                 [content]="providerFeedbackContent"></rappider-chart-base>
          </section>

          <section class="col-12">
            <rappider-chart-base [config]="administrativeEfficiencyConfig"
                                 [content]="administrativeEfficiencyContent"></rappider-chart-base>
          </section>
        </div>
      </nz-tab>
      <!-- END OF APPLICATIONS TAB -->

      <!-- ENROLLMENT TAB -->
      <nz-tab nzTitle="ENROLLMENT"
              [nzForceRender]="true">
        <div class="row">


          <section class="col-6">
            <rappider-chart-base [config]="predictedEnrollmentLineChartConfig"
                                 [content]="predictedEnrollmentLineChartContent"></rappider-chart-base>
          </section>
          <section class="col-6">
            <rappider-chart-base [config]="stateAndRegionalComparisonConfig"
                                 [content]="stateAndRegionalComparisonContent"></rappider-chart-base>
          </section>


          <section class="col-6">
            <rappider-chart-base [config]="scenarioAnalysisConfig"
                                 [content]="scenarioAnalysisContent"></rappider-chart-base>
          </section>
          <section class="col-6">
            <rappider-chart-base [config]="programIntegrityConfig"
                                 [content]="programIntegrityContent"></rappider-chart-base>
          </section>
          <section class="col-6">
            <rappider-chart-base [config]="smartEnrollmentConfig"></rappider-chart-base>
          </section>


          <section class="col-4">
            <rappider-chart-base [config]="enrollmentPieConfig"
                                 [content]="enrollmentPieContent"></rappider-chart-base>
          </section>

          <section class="col-4">
            <rappider-chart-base [config]="programEnrollmentBarConfig"
                                 [content]="programEnrollmentBarContent"></rappider-chart-base>
          </section>

          <section class="col-4">
            <rappider-chart-base [config]="yearlyEnrollmentGrowthConfig"
                                 [content]="yearlyEnrollmentGrowthContent"></rappider-chart-base>
          </section>

          <section class="col-6">
            <rappider-chart-base [config]="highCostConfig"
                                 [content]="highCostContent"></rappider-chart-base>
          </section>
          <section class="col-6">
            <rappider-chart-base [config]="realTimeBudgetConfig"
                                 [content]="realTimeBudgetContent"></rappider-chart-base>
          </section>
          <section class="col-6">
            <rappider-chart-base [config]="costPerBeneficiaryPredictionConfig"
                                 [content]="costPerBeneficiaryPredictionContent"></rappider-chart-base>
          </section>
          <section class="col-6">
            <rappider-chart-base [config]="beneficiarySatisfactionConfig"
                                 [content]="beneficiarySatisfactionContent"></rappider-chart-base>
          </section>

          <section class="col-6">
            <rappider-chart-base [config]="preventiveCareOptimizationConfig"
                                 [content]="preventiveCareOptimizationContent"></rappider-chart-base>
          </section>

          <section class="col-6">
            <rappider-chart-base [config]="claimsFraudConfig"></rappider-chart-base>
          </section>

          <section class="col-6">
            <rappider-chart-base [config]="spendingTrendAnalyticsConfig"></rappider-chart-base>
          </section>

        </div>
      </nz-tab>
      <!-- END OF ENROLLMENT TAB -->

      <!-- SERVICE UTILIZATION TAB -->
      <nz-tab nzTitle="SERVICE UTILIZATION"
              [nzForceRender]="true">
        <div class="row">

          <section class="col-4">
            <rappider-chart-base [config]="engagementConfig"
                                 [content]="engagementContent"></rappider-chart-base>
          </section>
          <section class="col-4">
            <rappider-chart-base [config]="utilizationOfServicesBarConfig"
                                 [content]="utilizationOfServicesBarContent"></rappider-chart-base>
          </section>
          <section class="col-4">
            <rappider-chart-base [config]="ruralVsUrbanConfig"
                                 [content]="ruralVsUrbanContent"></rappider-chart-base>
          </section>
          <section class="col-4">
            <rappider-chart-base [config]="emergingIssuesConfig"
                                 [content]="emergingIssuesContent"></rappider-chart-base>
          </section>
        </div>
      </nz-tab>
      <!-- END OF SERVICE UTILIZATION TAB -->

      <!-- FWA TAB -->
      <!-- END OF FWA TAB -->
    </nz-tabset>
    <!-- END OF TABS -->
  </section>

  <!-- Section 1: Program Enrollment and Demographics -->


  <!-- Section 2: Utilization of Services -->


  <!-- Section 3: Financial Performance -->
  <!-- <section>
    <h3>Claims Fraud Detection</h3>
    <p>Anomalous Claims Detected: 2,500 claims flagged</p>
    <p>Fraud Savings: $20 million recovered</p>

    <h3>Spending Trend Analytics:</h3>
    <p>Key Cost Drivers:</p>
    <ul>
      <li>Hospital Care: $2 billion</li>
      <li>Prescription Drugs: $1.5 billion</li>
      <li>Long-Term Care: $1 billion</li>
    </ul>
  </section> -->

  <!-- Section 4: Program Efficiency and Effectiveness -->
  <!-- <section>
    <h2>Program Efficiency and Effectiveness</h2>
    <h3>Administrative Efficiency</h3>
    <p>Average Application Processing Time: 15 days</p>
    <p>Claims Processing Time: 10 days</p>

    <h3>Provider Networks</h3>
    <p>Total Providers: 10,000</p>
    <p>Network Adequacy: 95% of beneficiaries within 10 miles of a provider</p>
  </section> -->

  <!-- Section 5: Data Analytics and Predictive Modeling -->
  <!-- <section>
    <h2>Data Analytics and Predictive Modeling</h2>
    <h3>Predictive Analytics</h3>
    <p>Predicted Increase in Enrollment: 5% next year</p>
    <p>Predicted Budget Shortfall: $200 million</p>
    <h3>Scenario Analysis</h3>
    <p>Policy Change Impact: 10% increase in child enrollment expected due to new legislation</p>
  </section> -->

  <!-- Section 6: Fiscal Impact Analysis -->
  <!-- <section>
    <h2>Fiscal Impact Analysis</h2>
    <h3>Automated Budget Analysis</h3>
    <p>Historical vs. Current Spend:</p>
    <ul>
      <li>Historical: $4.9 billion</li>
      <li>Current: $5 billion</li>
    </ul>
    <h3>Cost-Benefit Analysis Automation</h3>
    <p>Return on Investment: 10% ROI on recent Medicaid expansion</p>
  </section> -->

  <!-- Section 7: Compliance and Policy Monitoring -->
  <!-- <section>
    <h2>Compliance and Policy Monitoring</h2>
    <h3>Regulatory Compliance</h3>
    <p>Audit Completion Rate: 95%</p>
    <p>Corrective Actions Taken: 150</p>
  </section> -->

  <!-- Section 8: Beneficiary and Provider Engagement -->
  <!-- <section>
    <h2>Beneficiary and Provider Engagement</h2>
    <h3>Beneficiary Satisfaction</h3>
    <p>Satisfaction Rate: 85%</p>
    <p>Surveys Completed: 10,000</p>


    <h3>Provider Feedback</h3>
    <p>Positive Feedback: 75%</p>
    <p>Negative Feedback: 15%</p>
    <p>Neutral Feedback: 10%</p>
  </section> -->

  <!-- Section 9: Geographic and Regional Analysis -->
  <!-- <section>
    <h2>Geographic and Regional Analysis</h2>
    <h3>State and Regional Comparisons</h3>
    <p>Enrollment by State:</p>
    <ul>
      <li>State A: 300,000</li>
      <li>State B: 500,000</li>
      <li>State C: 400,000</li>
    </ul>
  </section> -->

  <!-- Section 10: Real-Time Alerts and Notifications -->
  <!-- <section>
    <h2>Real-Time Alerts and Notifications</h2>
    <h3>Emerging Issues</h3>
    <p>Spike in ER Visits: 10% increase last month</p>
    <p>Drop in Provider Participation: 5% decrease</p>


    <h3>Performance Benchmarks</h3>
    <p>Enrollment Target: 1.5 million</p>
    <p>Current Enrollment: 1.2 million</p>
  </section> -->
</div>
